import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store/reducers';

import * as AppraisalActions from '../../../store/actions/appraisal.actions';

@Component({
  selector: "app-appraisal-scan",
  templateUrl: "./appraisal-scan.component.html",
  styleUrls: ["./appraisal-scan.component.scss"],
})
export class AppraisalScanComponent implements OnInit {
  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  @ViewChild("scanner", { static: true }) scanner: ZXingScannerComponent;

  hasDevices = false;
  hasPermission: boolean;
  qrResultString: string;

  // availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  total: Observable<number>;
  private _includes: string[] = [
    "client",
    "contract",
    "supplier",
    "consultants",
    "consultantInCharge",
  ];

  ngOnInit() {
    
  }
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    console.log('camera found');
    this.hasDevices = Boolean(devices && devices.length);
  }

  handleQrCodeResult(resultString: string) {

    if(resultString) {
      console.log("Result: ", resultString);
      this.qrResultString = resultString;
      this.store$.dispatch(AppraisalActions.scanAppraisalSuccess({ code: this.qrResultString }));
      this.load();
    } else {
      this.store$.dispatch(AppraisalActions.scanAppraisalError());
    }
     
  }

  load() {
    this.store$.dispatch(
      AppraisalActions.loadAppraisals({
        page: -1,
        perPage: 0,
        filters: {
          id: this.qrResultString,
        },
        includes: this._includes,
      })
    );
    
  }

  close() {
    this.store$.dispatch(AppraisalActions.closeScanDialog());
  }
}
