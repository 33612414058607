import { closeHistoryDialog } from "./../../../store/actions/appraisal.actions";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import {
  AppraisalHistory,
  AppraisalHistoryFilters,
} from "src/app/commons/models/appraisal-history.model";
import { AppState } from "src/app/store/reducers";
import * as AppraisalHistorySelectors from "src/app/store/selectors/appraisal-history.selectors";

import {
  Appraisal,
  AppraisalFilters,
} from "../../../commons/models/appraisal.model";
import { PAGE_SIZE_OPTIONS } from "../../../helpers/table.helper";
import * as AppraisalHistoryActions from "../../../store/actions/appraisal-history.actions";
import * as AppraisalActions from "../../../store/actions/appraisal.actions";
import { Optional } from "@angular/core";
import { Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-appraisal-barcode",
  templateUrl: "./appraisal-barcode.component.html",
  styleUrls: ["./appraisal-barcode.component.scss"],
})
export class AppraisalBarcodeComponent implements OnInit {
  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  qrUrl: string;
  private _appraisal: Appraisal;
  pdfUrl: string;

  ngOnInit() {
    if (this.data) {
      this.appraisal = this.data.appraisal;
    }
    this.qrUrl = this.appraisal ? this.appraisal.qrUrl : null;
    console.log(this.qrUrl);
  }
  downloadPdf() {
    this.store$.dispatch(
      AppraisalActions.downloadPDF({ appraisal: this.appraisal })
    );
  }

  get appraisal(): Appraisal {
    return this._appraisal;
  }

  set appraisal(appraisal: Appraisal) {
    this._appraisal = appraisal;
  }

  close() {
    this.store$.dispatch(AppraisalActions.closeBarcodeDialog());
  }
}
