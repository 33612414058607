<mat-card class="mb-20">
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Stato iniziale</mat-label>
        <mat-select formControlName="initialState" multiple>
          <mat-select-trigger>
            <span *ngIf="filtersForm.value.initialState?.length == statuses.length">Tutti</span>
            <span *ngIf="filtersForm.value.initialState?.length < statuses.length">
              {{(filtersForm.value.initialState ? filtersForm.value.initialState[0] : '') | appraisalStatus }}
              <span *ngIf="filtersForm.value.initialState?.length > 1" class="additional-selection">
                (+{{filtersForm.value.initialState.length - 1}}
                {{filtersForm.value.initialState?.length === 2 ? 'altro' : 'altri'}})
              </span>
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let state of statuses" [value]="state" [ngClass]="state">
            {{state | appraisalStatus}}</mat-option>
        </mat-select>
      </mat-form-field>
    <mat-form-field class="u-full-width">
      <mat-label>Stato finale</mat-label>
      <mat-select formControlName="finalState" multiple>
        <mat-select-trigger>
          <span *ngIf="filtersForm.value.finalState?.length == statuses.length">Tutti</span>
          <span *ngIf="filtersForm.value.finalState?.length < statuses.length">
            {{(filtersForm.value.finalState ? filtersForm.value.finalState[0] : '') | appraisalStatus }}
            <span *ngIf="filtersForm.value.finalState?.length > 1" class="additional-selection">
              (+{{filtersForm.value.finalState.length - 1}}
              {{filtersForm.value.finalState?.length === 2 ? 'altro' : 'altri'}})
            </span>
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let state of statuses" [value]="state" [ngClass]="state">
          {{state | appraisalStatus}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>da </mat-label>
      <input matInput [matDatepicker]="fromDatePicker1" formControlName="creationStart" />
      <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>a </mat-label>
      <input matInput [matDatepicker]="toDatePicker1" formControlName="creationEnd" />
      <mat-datepicker-toggle matSuffix [for]="toDatePicker1"></mat-datepicker-toggle>
      <mat-datepicker #toDatePicker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field floatLabel="always" class="u-full-width">
      <mat-label>Utente</mat-label>
      <perizie-user-field formControlName="user">
      </perizie-user-field> 
    </mat-form-field> 
    </div>

    <span class="u-flex-full">
      <button mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">Filtra</button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-card>
