import { getCurrentFormControlName } from './../../../store/selectors/user.selectors';
import { Role } from "./../../../commons/models/user.model";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
} from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControlName, NgControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { Actions, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { filter, map, takeUntil, withLatestFrom } from "rxjs/operators";
import { User, UserFilters } from "src/app/commons/models/user.model";
import * as UserActions from "src/app/store/actions/user.actions";
import * as UserSelectors from "src/app/store/selectors/user.selectors";
import { AppState } from "src/app/store/reducers";
import * as AuthSelectors from "src/app/store/selectors/auth.selectors";

// export const USER_FIELD_MAT_FORM_FIELD_CONTROL: any = {
//   provide: NG_VALUE_ACCESSOR,
//   useExisting: forwardRef(() => UserFieldComponent),
//   multi: true
// };

export const USER_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => UserFieldComponent),
};

@Component({
  selector: "perizie-user-field",
  templateUrl: "./user-field.component.html",
  styleUrls: ["./user-field.component.scss"],
  providers: [USER_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class UserFieldComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<User> {
  private unsubscribe$ = new Subject<void>();
  private _value: User;
  private _disabled = false;
  currentUser: User;

  private _required = false;
  errorState: boolean; //TODO
  focused: boolean; //TODO
  controlType?: string = "user-selection-field";
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  currentFormControlName: string;

  @Input()
  userFilters: UserFilters;

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    @Optional() @Self() public ngControl: NgControl
  ) {

    this.actions$
      .pipe(
        ofType(UserActions.userSelected),
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.store$.select(UserSelectors.getCurrentFormControlName)),
        filter(([ _, currentFormControlName]) =>  currentFormControlName == this.currentFormControlName),
        map(([{ user }, _ ]) => (user ? new User(user.toDTO()) : null))
        
      )
      .subscribe((user) => (this.value = user));
        if (this.ngControl != null) {
          this.ngControl.valueAccessor = this;
        }
        this.store$
        .pipe(
          select(AuthSelectors.getCurrentUser),
          takeUntil(this.unsubscribe$),
          map((dto) => (dto ? new User(dto) : null))
        )
        .subscribe((user) => (this.currentUser = user));
    }
  stateChanges = new Subject<void>();
  id: string;

  ngAfterContentInit(): void {
    const control = this.ngControl && this.ngControl.control;
    if (control) {
      this.currentFormControlName = this.getControlName(control)
    }
 }

  private getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    //TODO
  }
  onContainerClick(event: MouseEvent): void {
    if (!this.disabled && this.currentUser.role !='supplier') {
      if ((event.target as Element).tagName.toLowerCase() != "mat-icon") {
        this.selectUser();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectUser() {
    if(this.value){
      this.userFilters = {
        roles: [this.value.role],
      };
    }
    this.store$.dispatch(UserActions.selectUser({ filters: this.userFilters,currentFormControlName: this.currentFormControlName }));
  }

  clearUser() {
    this.value = null;
  }

  set value(value: User) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): User {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: User): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
