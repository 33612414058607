
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { Actions, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Contract, ContractFilters } from "src/app/commons/models/contract.model";
import { User } from "src/app/commons/models/user.model";
import * as ContractActions from "src/app/store/actions/contract.actions";
import { AppState } from "src/app/store/reducers";
import * as AuthSelectors from "src/app/store/selectors/auth.selectors";

// export const COMPONENT_NAME_VALUE_ACCESSOR: any = {
//   provide: NG_VALUE_ACCESSOR,
//   useExisting: forwardRef(() => ContractFieldComponent),
//   multi: true
// };

export const CONTRACT_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => ContractFieldComponent),
};

@Component({
  selector: "perizie-contract-field",
  templateUrl: "./contract-field.component.html",
  styleUrls: ["./contract-field.component.scss"],
  providers: [CONTRACT_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class ContractFieldComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<Contract> {
  private unsubscribe$ = new Subject<void>();
  private _value: Contract;
  private _disabled = false;
  currentUser: User;


  private _required = false;
  errorState: boolean; //TODO
  focused: boolean; //TODO
  controlType?: string = "contract-selection-field";
  autofilled?: boolean;
  contractAriaDescribedBy?: string;

  @Input()
  contractFilters: ContractFilters;

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.actions$
      .pipe(
        ofType(ContractActions.contractSelected),
        takeUntil(this.unsubscribe$),
        map(({ contract }) => (contract ? new Contract(contract) : null))
      )
      .subscribe((contract) => {(this.value = contract);
        this.notifyValueChange();
      });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.store$
    .pipe(
      select(AuthSelectors.getCurrentUser),
      takeUntil(this.unsubscribe$),
      map((dto) => (dto ? new User(dto) : null))
    )
    .subscribe((user) => (this.currentUser = user));
  }
  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    //TODO
  }
  onContainerClick(event: MouseEvent): void {
    if (!this.disabled && this.currentUser.role !='supplier') {
      if ((event.target as Element).tagName.toLowerCase() != "mat-icon") {
        this.selectContract();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectContract() {
 
    this.store$.dispatch(ContractActions.selectContract({ filters: this.contractFilters }));
  }

  clearContract() {
    this.value = null;
  }

  set value(value: Contract) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): Contract {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: Contract): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
