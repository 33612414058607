import { Contract, ContractDTO } from 'src/app/commons/models/contract.model';
import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Client, ClientDTO } from './client.model';
import { User, UserDTO } from './user.model';
import { Appraisal, AppraisalDTO, AppraisalState } from './appraisal.model';


export interface AppraisalHistoryFilters {
  initialState?: AppraisalState[];
  finalState?: AppraisalState[];
  user?: User;
  creationStart?: Date;
  creationEnd?: Date;
  appraisal?: Appraisal;

}

export interface AppraisalHistoryDTO extends BaseDTO {
  initial_state: AppraisalState;
  final_state: AppraisalState;
  user_id: number;
  user: UserDTO;
  appraisal_id: number;
  appraisal:AppraisalDTO;

}

export class AppraisalHistory extends Base {
  initialState: AppraisalState;
  finalState: AppraisalState;
  user: User;
  appraisal:Appraisal;
 

  constructor(source: AppraisalHistoryDTO) {
    super(source);
    if (source) {
      this.initialState = source.initial_state;
      this.finalState = source.final_state;
      if (source.user) {
        this.user = new User(source.user);
        this.addLoadedRelation('user');
      }
      if (source.appraisal) {
        this.appraisal = new Appraisal(source.appraisal);
        this.addLoadedRelation('appraisal');
      }
      
    }
  }

  toDTO(): AppraisalHistoryDTO {
    let result: AppraisalHistoryDTO = <AppraisalHistoryDTO>super.toDTO();
    result.initial_state = this.initialState;
    result.final_state = this.finalState;
    result.user = this.user ? this.user.toDTO() : null;
    result.user_id = this.user? this.user.id: null;
    result.appraisal = this.appraisal ? this.appraisal.toDTO() : null;
    result.appraisal_id = this.appraisal ? this.appraisal.id: null;
    
    return result;
  }

}
