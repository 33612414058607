import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Role, User } from 'src/app/commons/models/user.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _user: User;

  currentUser: User;
  roles: Role[];

  userForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.store$.pipe(select(AuthSelectors.getCurrentUser), takeUntil(this.unsubscribe$), map(dto => dto ? new User(dto) : null)).subscribe(user => this.currentUser = user);
  }

  ngOnInit() {
    if (this.data) {
      this.user = this.data.user;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      role: ["", Validators.required],
    }
    if (this.user) {
      this.userForm = this.fb.group(group);
    } else {
      group["password"] = ["", Validators.required];
      group["confirmPass"] = ["", Validators.required];
      this.userForm = this.fb.group(group, {
        validator: this.checkIfMatchingPasswords("password", "confirmPass")
      });
    }
  }

  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  private ngOnChanges() {
    if (this.userForm) {
      this.userForm.reset();
      if (this.user) {
        this._initFormValues(this.user);
      }
    }

  }

  private _initFormValues(user: User) {
    if (user) {
      this.userForm.patchValue({
        name: user.name,
        email: user.email,
        password: user.password,
        role: user.role,
      });
    }
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
    this.ngOnChanges();
  }

  private _prepareSaveUser(): User {
    let savingUser: User = User.fromFormGroup(this.userForm, this.user);
    return savingUser;
  }

  save() {
    let unsavedEntity = this._prepareSaveUser();
    this.store$.dispatch(UserActions.saveUser({ user: unsavedEntity }));
  }

  close() {
    if (this.userForm.pristine) {
      this.store$.dispatch(UserActions.closeUserDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(UserActions.closeUserDialog())
          }
        });
    }
  }

  deleteUser() {
    if (this.user) {
      this.store$.dispatch(UserActions.deleteUser({ user: this.user.toDTO() }))
    }
  }

  changePassword() {
    if (this.user) {
      this.store$.dispatch(UserActions.changeUserPassword({ user: this.user.toDTO() }));
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
