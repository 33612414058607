<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="client">Modifica cliente: {{client.companyName}}</span>
    <span *ngIf="!client">Nuovo cliente</span>
  </span>
  <span *ngIf="client">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteClient()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="clientForm" novalidate>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Ragione sociale" formControlName="companyName" type="text" required>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Codice azienda" formControlName="companyCode" type="text" required>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Provincia" formControlName="province" type="text">
    </mat-form-field>
    <div class="formGroup__wrapper">
      <mat-label class="formGoup__label">
        Contatto primario
      </mat-label>
        <mat-form-field class="formGroup" >
          <input matInput placeholder="Nome" formControlName="contactName" type="text">
        </mat-form-field>
        <mat-form-field class="formGroup">
        
          <input matInput placeholder="Email" formControlName="contactEmail" type="email">
  
        </mat-form-field>

    </div>
  
  </form>

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="clientForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="clientForm.pristine || !clientForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
