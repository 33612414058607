import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Client } from 'src/app/commons/models/client.model';
import { Contract } from 'src/app/commons/models/contract.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as ContractActions from 'src/app/store/actions/contract.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-contract-edit',
  templateUrl: './contract-edit.component.html',
  styleUrls: ['./contract-edit.component.scss']
})
export class ContractEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _contract: Contract;
  filteredArticles: Observable<string[]>;


   articles = [
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2016",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2017",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2018",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2019",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2020",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2021",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2022",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2023",
    "L. 311/2004 - Anno 2019",
    "L. 311/2004 - Anno 2020",
    "L. 311/2004 - Anno 2021",
    "L. 311/2004 - Anno 2022",
    "L. 311/2004 - Anno 2023",
    "L. 311/2004_Detassazione costi personale R&S",
    "L. 311/2004 - Detassazione dei Costi di Personale dedicato all’Attività di R&S",
    "Legge di Bilancio 2017, art. 1, commi 9-13: Iper-ammortamento",
    "Naz_L. 160/2019 - Credito d’Imposta Beni Strumentali (ex Iper e Super ammortamento)",
    "Naz_L. 178/2020 - Credito d’Imposta Beni Strumentali 2021-2022 (ex Iper e Super ammortamento)",
    "Naz_DL 104/2020 - Rivalutazione Beni d'Impresa",
    "ATTESTAZIONI BENI 4.0"

  ];

  contractForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {


  }

  ngOnInit() {
    if (this.data) {
      this.contract = this.data.contract;
    }
    this._createForm();
    this.ngOnChanges();
    this.filteredArticles = this.contractForm.get("article").valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      clients: ["",Validators.required],
      protocolSub: ["", Validators.required],
      contractCode: [""],
      article: ["", Validators.required],
      commercialEmail: ["", Validators.email],
      technicianEmail: ["",Validators.email]
    }

    this.contractForm = this.fb.group(group);

  }

  private ngOnChanges() {
    if (this.contractForm) {
      this.contractForm.reset();
      if (this.contract) {
        this._initFormValues(this.contract);
      }
    }

  }

  private _initFormValues(contract: Contract) {
    if (contract) {
      this.contractForm.patchValue({
        clients:  contract.clients ? contract.clients : [],
        protocolSub: contract.protocolSub,
        contractCode: contract.contractCode,
        article: contract.article,
        commercialEmail: contract.commercialEmail,
        technicianEmail: contract.technicianEmail

      });
    }
  }

  get contract(): Contract {
    return this._contract;
  }

  set contract(contract: Contract) {
    this._contract = contract;
    this.ngOnChanges();
  }

  private _prepareSaveContract(): Contract {
    let savingContract: Contract = Contract.fromFormGroup(this.contractForm, this.contract);
    return savingContract;
  }

  save() {
    let unsavedEntity = this._prepareSaveContract();
    this.store$.dispatch(ContractActions.saveContract({ contract: unsavedEntity }));
  }

  close() {
    if (this.contractForm.pristine) {
      this.store$.dispatch(ContractActions.closeContractDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(ContractActions.closeContractDialog())
          }
        });
    }
  }

  // private _getClientsFromForm(): Client[] {
  //   let clients: Client[] = [];
  //   this.clientFieldForm.controls.forEach(fieldForm => {
  //     let client: Client = Client.fromFormGroup(fieldForm);
  //     clients.push(client);
  //   });
  //   return clients;
  // }
  // get clientFieldForm(): FormArray {
  //   return this.contractForm.get("clients") as FormArray;
  // }

  deleteContract() {
    if (this.contract) {
      this.store$.dispatch(ContractActions.deleteContract({ contract: this.contract.toDTO() }))
    }
  }


  revert() {
    this.ngOnChanges();
  }

  showClient(client: Client): string {
    return client ? `${client.companyName} ` : null;
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.articles.filter(article => article.toLowerCase().includes(filterValue));
  }
}
