import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { AppraisalDTO, AppraisalFilters } from '../../models/appraisal.model';


@Injectable({
  providedIn: "root"
})
export class LaravelAppraisalService {


  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/appraisals",
      show: environment.baseUrl + "/api/appraisal",
      store: environment.baseUrl + "/api/appraisal",
      destroy: environment.baseUrl + "/api/appraisal",
      restore: environment.baseUrl + "/api/appraisal/restore",
      download: environment.baseUrl + "/api/download",
      export: environment.baseUrl + "/api/appraisal/records",
      pdf: environment.baseUrl + "/api/appraisals/pdf",

    };
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters?: AppraisalFilters,
    includes?: string[]
  ): Observable<ListResultDTO<AppraisalDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.id)
        params["id"] = filters.id;
      if (filters.state)
        params["statuses[]"] = filters.state;
      if (filters.client)
        params["client"] = filters.client;
      if (filters.consultant)
        params["consultant_id"] = filters.consultant.id;
      if (filters.supplier)
        params["supplier_id"] = filters.supplier.id;
      if (filters.consultantInCharge)
        params["consultant_in_charge_id"] = filters.consultantInCharge.id;
      if (filters.province)
        params["province"] = filters.province;
      if (filters.article)
        params["article"] = filters.article;
      if (filters.externalId)
        params["external_id"] = filters.externalId;
      if (filters.creationStart)
        params["from"] = formatDateForBackend(filters.creationStart);
      if (filters.creationEnd)
        params["to"] = formatDateForBackend(filters.creationEnd);
      if (filters.updateStart)
        params["updated_from"] = formatDateForBackend(filters.updateStart);
      if (filters.updateEnd)
        params["updated_to"] = formatDateForBackend(filters.updateEnd);
      if (filters.cancelled != undefined)
        params["cancelled"] = filters.cancelled;
      if (filters.blocked != undefined)
        params["blocked"] = filters.blocked;
      if (filters.includeArchived)
        params["include_archived"] = "true";
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<AppraisalDTO>>(this.ROUTES.list, {
        params: new HttpParams({fromObject: params})
      });
    } else {
      return this.httpClient.get<AppraisalDTO[]>(this.ROUTES.list, {
        params: new HttpParams({fromObject: params})
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }


  public getAppraisalById(id: string): Observable<AppraisalDTO> {
    let params = {id: "" + id};
    return this.httpClient.get<AppraisalDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });

  }


  public upsert(appraisal: AppraisalDTO, attachment?: any): Observable<AppraisalDTO> {

    let formData = new FormData();
    formData.append("appraisal", JSON.stringify(appraisal));
    if (attachment) {
      formData.append("attachment", attachment)
    }
    return this.httpClient.post<AppraisalDTO>(this.ROUTES.store, formData);

  }

  public restore(id: number): Observable<any> {
    return this.httpClient.put(this.ROUTES.restore, {id});
  }

  public delete(id: number): Observable<any> {
    let params = {id: "" + id};
    return this.httpClient.delete(this.ROUTES.destroy, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }


  public downloadFile(id: number): Observable<Blob> {
    let params = {
      id: "" + id
    };
    return this.httpClient.get(this.ROUTES.download, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }

  public exportAppraisals(filters: AppraisalFilters): Observable<Blob> {
    let params = {};
    if (filters) {
      if (filters.state) params["statuses[]"] = filters.state;
      if (filters.client)
        params["client"] = filters.client;
      if (filters.consultant)
        params["consultant_id"] = filters.consultant.id;
      if (filters.supplier)
        params["supplier_id"] = filters.supplier.id;
      if (filters.consultantInCharge)
        params["consultant_in_charge_id"] = filters.consultantInCharge.id;
      if (filters.province)
        params["province"] = filters.province;
      if (filters.article)
        params["article"] = filters.article;
      if (filters.externalId)
        params["external_id"] = filters.externalId;
      if (filters.creationStart)
        params["from"] = formatDateForBackend(filters.creationStart);
      if (filters.creationEnd)
        params["to"] = formatDateForBackend(filters.creationEnd);
      if (filters.updateStart)
        params["updated_from"] = formatDateForBackend(filters.updateStart);
      if (filters.updateEnd)
        params["updated_to"] = formatDateForBackend(filters.updateEnd);
      if (filters.cancelled != undefined)
        params["cancelled"] = filters.cancelled;
      if (filters.blocked != undefined)
        params["blocked"] = filters.blocked;
      if (filters.includeArchived)
        params["include_archived"] = "true";
    }

    return this.httpClient.get(this.ROUTES.export, {
      params: new HttpParams({
        fromObject: params,
      }),
      responseType: "blob",
    });
  }

  public downloadPdf(id: number): Observable<Blob> {
    let params = {
      id: "" + id
    };
    return this.httpClient.get(this.ROUTES.pdf, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }




}








