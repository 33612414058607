
  <mat-chip-list #chipList>
    <mat-chip color="primary" *ngFor="let client of value; let i = index;" [removable]="true"
      [selectable]="false" (removed)="removeClient(i)">
      {{client.companyName}}
      <mat-icon matChipRemove>cancel</mat-icon>
  
    </mat-chip>
    <button type="button" style="margin: 5px" matTooltip="Aggiungi cliente" (click)="selectClient()" mat-icon-button
    mat-raised-button>
  <mat-icon>add</mat-icon>
  </button>

  

