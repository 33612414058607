import { createAction, props } from '@ngrx/store';

import { User, UserDTO, UserFilters } from '../../commons/models/user.model';

export const loadUsers = createAction('[Users] Load users', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: UserFilters }>());
export const loadUsersCompleted = createAction('[Users] Load users Completed', props<{ users: UserDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: UserFilters }>());
export const loadUsersFailed = createAction('[Users] Load users Failed', props<{ error: any }>());

export const changePage = createAction('[Users] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Users] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Users] Change filters', props<{ filters: UserFilters }>());

export const editUser = createAction('[Users] Edit user', props<{ user: User }>());
export const userDialogOpened = createAction('[Users] Detail dialog opened', props<{ dialogId: string }>());
export const closeUserDialog = createAction('[Users] Close detail dialog');

export const saveUser = createAction('[Users] Save user', props<{ user: User }>());
export const saveUserCompleted = createAction('[Users] Save user completed', props<{ user: UserDTO }>());
export const saveUserFailed = createAction('[Users] Save user failed', props<{ error: any }>());

export const deleteUser = createAction('[Users] Delete user', props<{ user: UserDTO }>());
export const deleteUserCompleted = createAction('[Users] Delete user completed', props<{ user: UserDTO }>());
export const deleteUserCancelled = createAction('[Users] Delete user cancelled');
export const deleteUserFailed = createAction('[Users] Delete user failed', props<{ error: any }>());

export const changeUserPassword = createAction('[Users] Change user password', props<{ user?: UserDTO }>());
export const changePasswordDialogOpen = createAction('[Users] Change password dialog opened', props<{ dialogId: string }>());
export const closeChangePasswordDialog = createAction('[Users] Close change password dialog');

export const updatePassword = createAction('[Users] Update password', props<{ newPassword: string, user?: UserDTO }>());
export const updatePasswordCompleted = createAction('[Users] Update password completed', props<{ user: UserDTO }>());
export const updatePasswordFailed = createAction('[Users] Update password failed', props<{ error: any }>());

export const selectUser = createAction('[Users] Select user', props<{ filters?: UserFilters,  currentFormControlName: string }>());
export const selectionDialogOpened = createAction('[Users] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Users] Close selection dialog');
export const userSelected = createAction('[Users] User selected', props<{ user: User }>());
