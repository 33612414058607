import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { Contract, ContractFilters } from 'src/app/commons/models/contract.model';

import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';


export type ContractsColumn = "id" | "client" | "protocolSub" | "contractCode" | "article" | "commercialEmail" | "technicianEmail" | "actions";

@Component({
  selector: 'perizie-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  contracts: Observable<Contract[]>;

  @Input()
  defaultFilters: ContractFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ContractsColumn[] = ["id", "client", "protocolSub", "contractCode", "article", "commercialEmail", "technicianEmail", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ContractFilters> = new EventEmitter<ContractFilters>();

  @Output()
  onSelectContract: EventEmitter<Contract> = new EventEmitter<Contract>();
  @Output()
  onAddContract: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditContract: EventEmitter<Contract> = new EventEmitter<Contract>();

  constructor() { 
    
  }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addContract() {
    this.onAddContract.emit();
  }

  editContract(contract: Contract) {
    this.onEditContract.emit(contract);
  }

  selectContract(contract: Contract) {
    this.onSelectContract.emit(contract);
  }

  onFilter(filters: ContractFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
