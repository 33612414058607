<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="user">Modifica utente: {{user.name}}</span>
    <span *ngIf="!user">Nuovo utente</span>
  </span>
  <span *ngIf="user">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="changePassword()">
        <mat-icon>vpn_key</mat-icon>
        Cambia Password
      </button>
      <button mat-menu-item (click)="deleteUser()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="userForm" novalidate>
    <!-- <div class="u-flex-full"> -->
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Nome" formControlName="name" type="text" required>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="E-mail" formControlName="email" type="email" required>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <!-- </div> -->
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Ruolo</mat-label>
        <mat-select formControlName="role" required>
          <mat-option value="admin">{{"admin" | userRole}}</mat-option>
          <mat-option value="consultant">{{"consultant" | userRole}}</mat-option>
          <mat-option value="supplier">{{"supplier" | userRole}}</mat-option>
        </mat-select>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="!user" class="u-full-width">
      <span class="u-flex-full">
        <mat-form-field>
          <input type="password" placeholder="Password" matInput formControlName="password" required>
          <mat-error>Campo obbligatorio</mat-error>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <input type="password" placeholder="Conferma password" matInput formControlName="confirmPass">
          <mat-error *ngIf="userForm.controls['confirmPass'].errors?.notEquivalent">Le
            password non coincidono</mat-error>
        </mat-form-field>
      </span>

    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="userForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="userForm.pristine || !userForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
