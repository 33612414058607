import { Action, createReducer, on } from '@ngrx/store';
import { AppraisalHistoryDTO } from 'src/app/commons/models/appraisal-history.model';
import { AppraisalDTO } from 'src/app/commons/models/appraisal.model';
import * as AppraisalHistoryActions from '../actions/appraisal-history.actions';
import { AppraisalFilters } from './../../commons/models/appraisal.model';

export interface AppraisalHistoryState {
  list: AppraisalHistoryDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: AppraisalFilters,
};

const initialState: AppraisalHistoryState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,

};

const appraisalHistoryReducer = createReducer(
  initialState,
  on(AppraisalHistoryActions.loadAppraisalsHistoryCompleted, (state, { appraisalsHistory, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: appraisalsHistory, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(AppraisalHistoryActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
);

export function reducer(state: AppraisalHistoryState | undefined, action: Action) {
  return appraisalHistoryReducer(state, action);
}

