import { Component, Input } from '@angular/core';

import { AppraisalState } from './../../../commons/models/appraisal.model';

@Component({
  selector: 'appraisal-status',
  templateUrl: './appraisal-status.component.html',
  styleUrls: ['./appraisal-status.component.scss']
})
export class AppraisalStatusComponent {

  @Input()
  state: AppraisalState
}
