<perizie-contract-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</perizie-contract-filters>
<mat-card class="u-center-text" *ngIf="!(contracts | async).length">
  <h4>Nessun contratto trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addContract()">Aggiungi</button>
</mat-card>
<div [hidden]="!(contracts | async).length">
  <table mat-table matSort [dataSource]="contracts">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id"> ID </th>
      <td mat-cell *matCellDef="let contract"> {{contract.id}} </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef> Cliente </th>
      <td mat-cell *matCellDef="let contract">
        <span *ngFor="let client of contract.clients">{{client?.companyName}}, </span>

      </td>
    </ng-container>

    <ng-container matColumnDef="protocolSub">
      <th mat-header-cell *matHeaderCellDef> Protocollo </th>
      <td mat-cell *matCellDef="let contract">
        {{contract.protocolSub}}
      </td>
    </ng-container>

    <ng-container matColumnDef="contractCode">
      <th mat-header-cell *matHeaderCellDef> Codice contratto </th>
      <td mat-cell *matCellDef="let contract">
        {{contract.contractCode}}
      </td>
    </ng-container>

    <ng-container matColumnDef="article">
      <th mat-header-cell *matHeaderCellDef> Articolo </th>
      <td mat-cell *matCellDef="let contract">
        {{contract.article }}
      </td>
    </ng-container>
    <ng-container matColumnDef="commercialEmail">
      <th mat-header-cell *matHeaderCellDef> Commerciale </th>
      <td mat-cell *matCellDef="let contract">
        {{contract.commercialEmail }}
      </td>
    </ng-container>
    <ng-container matColumnDef="technicianEmail">
      <th mat-header-cell *matHeaderCellDef> Tecnico </th>
      <td mat-cell *matCellDef="let contract">
        {{contract.technicianEmail }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation();addContract()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let contract">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editContract(contract)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectContract(contract)">Seleziona</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
