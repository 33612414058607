<perizie-client-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</perizie-client-filters>
<mat-card class="u-center-text" *ngIf="!(clients | async).length">
  <h4>Nessun cliente trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addClient()">Aggiungi</button>
</mat-card>
<div [hidden]="!(clients | async).length">
  <table mat-table matSort [dataSource]="clients">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id"> ID </th>
      <td mat-cell *matCellDef="let client"> {{client.id}} </td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef> Ragione sociale </th>
      <td mat-cell *matCellDef="let client">
        {{client.companyName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="companyCode">
      <th mat-header-cell *matHeaderCellDef> Codice azienda </th>
      <td mat-cell *matCellDef="let client">
        {{client.companyCode}}
      </td>
    </ng-container>

    <ng-container matColumnDef="province">
      <th mat-header-cell *matHeaderCellDef> Provincia </th>
      <td mat-cell *matCellDef="let client">
        {{client.province }}
      </td>
    </ng-container>
    <ng-container matColumnDef="contactName">
      <th mat-header-cell *matHeaderCellDef> Nominativo contatto primario </th>
      <td mat-cell *matCellDef="let client">
        {{client.contactName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="contactEmail">
      <th mat-header-cell *matHeaderCellDef> Email contatto primario </th>
      <td mat-cell *matCellDef="let client">
        {{client.contactEmail }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation();addClient()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let client">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editClient(client)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectClient(client)">Seleziona</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
