import { createSelector } from '@ngrx/store';

import { selectContractState } from '../reducers';
import { ContractState } from '../reducers/contract.reducer';

// Contract Selectors

export const getContractsTableState = createSelector(
  selectContractState,
  (state: ContractState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getContracts = createSelector(
  selectContractState,
  (state: ContractState) => state.list
);

export const getTotalContracts = createSelector(
  getContractsTableState,
  (tableState) => tableState.total
);

export const getContractsCurrentPage = createSelector(
  getContractsTableState,
  (tableState) => tableState.currentPage
);

export const getContractsPerPage = createSelector(
  getContractsTableState,
  (tableState) => tableState.perPage
);

export const getContractsOrder = createSelector(
  getContractsTableState,
  (tableState) => tableState.order
);

export const getContractsDirection = createSelector(
  getContractsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getContractsTableState,
  (tableState) => tableState.filters
);

export const getContractDialogId = createSelector(
  selectContractState,
  (state: ContractState) => state.dialogId
);

export const getSelectionDialogId = createSelector(
  selectContractState,
  (state: ContractState) => state.selectionDialogId
);
