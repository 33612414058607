import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { AppraisalHistory, AppraisalHistoryFilters } from 'src/app/commons/models/appraisal-history.model';

import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';


export type AppraisalHistorysColumn = "initialState" | "finalState" | "date" | "user";

@Component({
  selector: 'perizie-appraisal-history-list',
  templateUrl: './appraisal-history-list.component.html',
  styleUrls: ['./appraisal-history-list.component.scss']
})
export class AppraisalHistoryListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  appraisalsHistory: Observable<AppraisalHistory[]>;

  @Input()
  defaultFilters: AppraisalHistoryFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: AppraisalHistorysColumn[] = ["initialState", "finalState", "date", "user"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<AppraisalHistoryFilters> = new EventEmitter<AppraisalHistoryFilters>();


  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  onFilter(filters: AppraisalHistoryFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
