import { createAction, props } from '@ngrx/store';

import {  AppraisalHistoryDTO, AppraisalHistoryFilters } from '../../commons/models/appraisal-history.model';

export const loadAppraisalsHistory = createAction('[Appraisals history] Load appraisals history', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: AppraisalHistoryFilters, includes?: string[] }>());
export const loadAppraisalsHistoryCompleted = createAction('[Appraisals history] Load appraisals history Completed', props<{ appraisalsHistory: AppraisalHistoryDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: AppraisalHistoryFilters, includes?: string[] }>());
export const loadAppraisalsHistoryFailed = createAction('[Appraisals history] Load appraisals history Failed', props<{ error: any }>());

export const changePage = createAction('[Appraisals history] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Appraisals history] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Appraisals history] Change filters', props<{ filters: AppraisalHistoryFilters }>());




