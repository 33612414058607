import { createSelector } from '@ngrx/store';

import { selectUserState } from '../reducers';
import { UserState } from '../reducers/user.reducer';

// User Selectors

export const getUsersTableState = createSelector(
  selectUserState,
  (state: UserState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters } }
);

export const getUsers = createSelector(
  selectUserState,
  (state: UserState) => state.list
);

export const getTotalUsers = createSelector(
  getUsersTableState,
  (tableState) => tableState.total
);

export const getUsersCurrentPage = createSelector(
  getUsersTableState,
  (tableState) => tableState.currentPage
);

export const getUsersPerPage = createSelector(
  getUsersTableState,
  (tableState) => tableState.perPage
);

export const getUsersOrder = createSelector(
  getUsersTableState,
  (tableState) => tableState.order
);

export const getUsersDirection = createSelector(
  getUsersTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getUsersTableState,
  (tableState) => tableState.filters
);

export const getUserDialogId = createSelector(
  selectUserState,
  (state: UserState) => state.dialogId
);

export const getChangePasswordDialogId = createSelector(
  selectUserState,
  (state: UserState) => state.changePasswordDialogId
);

export const getSelectionDialogId = createSelector(
  selectUserState,
  (state: UserState) => state.selectionDialogId
);
export const getCurrentFormControlName = createSelector(
  selectUserState,
  (state: UserState) => state.currentFormControlName
);
export const getSelectedUser = createSelector(
  selectUserState,
  (state: UserState) => state.user
);
