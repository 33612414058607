import { Pipe, PipeTransform } from '@angular/core';

import { AppraisalState } from './../../../commons/models/appraisal.model';

@Pipe({
  name: 'appraisalStatus'
})
export class AppraisalStatusPipe implements PipeTransform {

  transform(value: AppraisalState): unknown {
    switch (value) {
      case "under_commitment":
        return "Impegno di lavorazione";
      case "under_process":
        return "In lavorazione";
      case "complete":
        return "Completata";
      case "delivered_to_golden_group":
        return "Consegnata al Golden Group";
      case "delivered_to_consultant":
        return "Consegnata al consulente";
      case "delivered_to_client":
        return "Consegnata al cliente";
    }
  }

}
