import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppraisalBarcodeComponent } from 'src/app/modules/home/appraisal-barcode/appraisal-barcode.component';
import { AppraisalHistoryComponent } from 'src/app/modules/home/appraisal-history/appraisal-history.component';
import { AppraisalScanComponent } from 'src/app/modules/home/appraisal-scan/appraisal-scan.component';
import {
  AppraisalMobileEditComponent,
} from 'src/app/modules/home/appraisals-mobile/appraisal-mobile-edit/appraisal-mobile-edit.component';
import { AppraisalFiltersComponent } from 'src/app/modules/shared/appraisal-filters/appraisal-filters.component';
import { environment } from 'src/environments/environment';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelAppraisalService } from '../../commons/services/backend/laravel-appraisal.service';
import { AppraisalEditComponent } from '../../modules/home/appraisals/appraisal-edit/appraisal-edit.component';
import * as AppraisalActions from '../actions/appraisal.actions';
import { AppState } from '../reducers';
import { getAppraisalsTableState, getHistoryDialogId, getSelectionDialogId } from '../selectors/appraisal.selectors';
import { closeScanDialog } from './../actions/appraisal.actions';
import {
  getAppraisalDialogId,
  getBarcodeDialogId,
  getFilterDialogId,
  getFilters,
  getScanDialogId,
  getStatusDialogId,
} from './../selectors/appraisal.selectors';


@Injectable()
export class AppraisalEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.saveAppraisalFailed),
      tap(({error}) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), {dispatch: false}
  );

  loadAppraisals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.loadAppraisals),
      switchMap(({page, perPage, order, direction, filters, includes}) => {
        return this.appraisalService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              AppraisalActions.loadAppraisalsCompleted({appraisals: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes})),
            catchError(error => {
              return of(AppraisalActions.loadAppraisalsFailed({error}))
            })
          )
      })
    )
  );

  loadAppraisalById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.loadAppraisalById),
      switchMap(({id}) => {
        return this.appraisalService.getAppraisalById(id)
          .pipe(
            map(result =>
              AppraisalActions.loadAppraisalByIdCompleted({appraisal: result})),
            catchError(error => {
              return of(AppraisalActions.loadAppraisalByIdFailed({error}))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.changePage),
      withLatestFrom(this.store$.select(getAppraisalsTableState)),
      map(([{page, size}, {total, currentPage, perPage, direction, order, filters, includes}]) => AppraisalActions.loadAppraisals({page: page, perPage: size, order, direction, filters, includes}))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.changeSort),
      withLatestFrom(this.store$.select(getAppraisalsTableState)),
      map(([action, {total, currentPage, perPage, direction, order, filters, includes}]) => AppraisalActions.loadAppraisals({page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes}))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.changeFilters),
      withLatestFrom(this.store$.select(getAppraisalsTableState)),
      tap(() => AppraisalActions.closeAppraisalFilterDialog()),
      map(([{filters}, {total, currentPage, perPage, direction, order, includes}]) => AppraisalActions.loadAppraisals({page: currentPage, perPage: perPage, order, direction, filters, includes})),
    )
  );

  editAppraisal$ = createEffect(() => this.actions$.pipe(
    ofType(AppraisalActions.editAppraisal),
    map(({appraisal}) => {
      let dialogRef = this.dialog.open(AppraisalEditComponent, {
        data: {
          appraisal
        }
      });
      return AppraisalActions.appraisalDialogOpened({dialogId: dialogRef.id});
    }))
  );

  filterAppraisal$ = createEffect(() => this.actions$.pipe(
    ofType(AppraisalActions.filterAppraisal),
    withLatestFrom(this.store$.select(getFilters)),
    map(([{filters}, _]) => {
      let dialogRef = this.dialog.open(AppraisalFiltersComponent, {
        data: {
          filters
        }
      });
      return AppraisalActions.appraisalFilterDialogOpened({filterDialogId: dialogRef.id});
    }))
  );

  changeStatus$ = createEffect(() => this.actions$.pipe(
    ofType(AppraisalActions.changeStatus),
    map(({appraisal}) => {
      let dialogRef = this.dialog.open(AppraisalMobileEditComponent, {
        data: {
          appraisal
        }
      });
      return AppraisalActions.appraisalStatusDialogOpened({statusDialogId: dialogRef.id});
    }))
  );

  saveAppraisal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.saveAppraisal),
      mergeMap(({appraisal, attachment}) =>
        this.appraisalService.upsert(appraisal.toDTO(), attachment)
          .pipe(
            map(result =>
              AppraisalActions.saveAppraisalCompleted({appraisal: result})
            ),
            catchError(error => of(AppraisalActions.saveAppraisalFailed({error})))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.saveAppraisalCompleted),
      map(action => action.appraisal),
      tap(appraisal => this.alertService.showConfirmMessage(`Perizia ${appraisal.id} salvata con successo`)),
      map(() => AppraisalActions.closeAppraisalDialog()),
    )
  );


  deleteAppraisal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.deleteAppraisal),
      switchMap(({appraisal}) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare la perizia ${appraisal.id}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.appraisalService.delete(appraisal.id)
                  .pipe(
                    map(() => AppraisalActions.deleteAppraisalCompleted({appraisal})),
                    catchError(error => of(AppraisalActions.deleteAppraisalFailed({error})))
                  )

                : of(AppraisalActions.deleteAppraisalCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.deleteAppraisalCompleted),
      tap(({appraisal}) => this.alertService.showConfirmMessage(`Perizia ${appraisal.id} eliminata con successo`)),
      map(() => AppraisalActions.closeAppraisalDialog())
    )
  );

  restoreAppraisal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.restoreAppraisal),
      switchMap(({appraisal}) =>
        this.alertService.showConfirmDialog('Conferma ripristino', `Sei sicuro di voler ripristinare la perizia ${appraisal.id}?`)
          .pipe(
            filter(confirm => !!confirm),
            switchMap(() =>
              this.appraisalService.restore(appraisal.id)
                .pipe(
                  map(() => AppraisalActions.restoreAppraisalCompleted({appraisal})),
                  catchError(error => of(AppraisalActions.restoreAppraisalFailed({error})))
                )
            )
          )
      )
    )
  );

  onRestoreCompleted$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AppraisalActions.restoreAppraisalCompleted),
    tap(({appraisal}) => this.alertService.showConfirmMessage(`Perizia ${appraisal.id} ripristinata con successo`)),
    map(() => AppraisalActions.closeAppraisalDialog())
  )
);

  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.closeAppraisalDialog),
      withLatestFrom(this.store$.select(getAppraisalDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), {dispatch: false}
  );

  closeFilterDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.closeAppraisalFilterDialog),
      withLatestFrom(this.store$.select(getFilterDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), {dispatch: false}
  );

  closeStatusDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.closeAppraisalStatusDialog),
      withLatestFrom(this.store$.select(getStatusDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), {dispatch: false}
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.saveAppraisalCompleted),
      withLatestFrom(this.store$.select(getAppraisalsTableState)),
      map(([_, {currentPage, perPage, direction, order, filters, includes}]) => AppraisalActions.loadAppraisals({page: currentPage, perPage, order, direction, filters, includes}))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.deleteAppraisalCompleted, AppraisalActions.restoreAppraisalCompleted),
      withLatestFrom(this.store$.select(getAppraisalsTableState)),
      map(([_, {currentPage, perPage, direction, order, filters, includes}]) => AppraisalActions.loadAppraisals({page: currentPage, perPage, order, direction, filters, includes}))
    )
  );

  appraisalsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.appraisalSelected),
      map(() => AppraisalActions.closeSelectionDialog())
    ))

  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), {dispatch: false}
  );

  openHistory$ = createEffect(() => this.actions$.pipe(
    ofType(AppraisalActions.openHistory),
    map(({appraisal}) => {
      let dialogRef = this.dialog.open(AppraisalHistoryComponent, {
        data: {
          appraisal
        }
      });
      return AppraisalActions.historyDialogOpened({historyDialogId: dialogRef.id});
    }))
  );

  closeHistoryDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.closeHistoryDialog),
      withLatestFrom(this.store$.select(getHistoryDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), {dispatch: false}
  );

  openBarcode$ = createEffect(() => this.actions$.pipe(
    ofType(AppraisalActions.openBarcode),
    map(({appraisal}) => {
      let dialogRef = this.dialog.open(AppraisalBarcodeComponent, {
        data: {
          appraisal
        }
      });
      return AppraisalActions.barcodeDialogOpened({barcodeDialogId: dialogRef.id});
    }))
  );

  closeBarcodeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.closeBarcodeDialog),
      withLatestFrom(this.store$.select(getBarcodeDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), {dispatch: false}
  );

  downloadAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.downloadAttachment),
      tap(({fileName}) => {
        saveAs(environment.baseUrl + "/storage/attachments/" + fileName);
      })
    ), {dispatch: false}
  );

  exportRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.exportRecords),
      switchMap(({filters}) =>
        this.appraisalService.exportAppraisals(filters).pipe(
          map((result) =>
            AppraisalActions.exportRecordsCompleted({
              blob: new Blob([result], {type: "text/csv"}),
            })
          ),
          tap(() =>
            this.alertService.showConfirmMessage(
              `Esportazione lista perizie generata con successo`
            )
          ),
          catchError((error) =>
            of(AppraisalActions.exportRecordsFailed({error}))
          )
        )
      )
    )
  );

  downloadrecords$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppraisalActions.exportRecordsCompleted),
        tap(({blob}) => {
          const fileName = `export_perizie.csv`;
          saveAs(blob, fileName);
        })
      ),
    {dispatch: false}
  );

  getPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.downloadPDF),
      switchMap(({appraisal}) =>
        this.appraisalService.downloadPdf(appraisal.id).pipe(
          map((result) =>
            AppraisalActions.downloadPdfCompleted({
              blob: new Blob([result], {type: "text/pdf"}),
            })
          ),
          tap(() =>
            this.alertService.showConfirmMessage(
              `PDF QrCode scaricato con successo`
            )
          ),
          catchError((error) =>
            of(AppraisalActions.exportRecordsFailed({error}))
          )
        )
      )
    )
  );


  downloadPdf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppraisalActions.downloadPdfCompleted),
        tap(({blob}) => {
          saveAs(blob, `qr_code.pdf`);
        })
      ),
    {dispatch: false}
  );

  scanAppraisal$ = createEffect(() => this.actions$.pipe(
    ofType(AppraisalActions.scanAppraisal),
    map(() => {
      let dialogRef = this.dialog.open(AppraisalScanComponent);
      return AppraisalActions.scanDialogOpened({scanDialogId: dialogRef.id});
    }))
  );

  scanAppraisalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.scanAppraisalSuccess),
      tap(() =>
        this.alertService.showConfirmMessage(
          " ✔️ La scansione del QR Code è stata effettuata con successo."
        )

      ),
      map(() => AppraisalActions.closeScanDialog()),
    ));

  scanAppraisalError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppraisalActions.scanAppraisalError),
        tap((_) =>
          this.alertService.showConfirmMessage(
            " ⚠️ La scansione del QR Code non è andata a buon fine."
          )
        )
      ),
    {dispatch: false}
  );

  closeScanDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalActions.closeScanDialog),
      withLatestFrom(this.store$.select(getScanDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private appraisalService: LaravelAppraisalService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}
