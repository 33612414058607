import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/commons/models/user.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import { UsersColumn } from 'src/app/modules/shared/user-list/user-list.component';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import * as UserSelectors from 'src/app/store/selectors/user.selectors';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import { UserFilters } from './../../../commons/models/user.model';



@Component({
  selector: 'perizie-users-selection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss']
})
export class UserSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  users: Observable<User[]>;
  filters: Observable<UserFilters>;
  currentUser: User;

  @Input()
  defaultFilters: UserFilters = {
    search: "",
    roles: ["admin", "consultant", "supplier"]
  }

  displayedColumns: UsersColumn[];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.users = this.store$.pipe(select(UserSelectors.getUsers), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new User(dto)) : null));
    this.total = this.store$.pipe(select(UserSelectors.getTotalUsers), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(UserSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(UserActions.loadUsers(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(UserActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(UserActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: UserFilters) {
    this.store$.dispatch(UserActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.store$.pipe(select(AuthSelectors.getCurrentUser), takeUntil(this.unsubscribe$), map(dto => dto ? new User(dto) : null)).subscribe(user => this.currentUser = user);
    if (this.currentUser.role == 'admin') {
      this.displayedColumns = ["name", "email", "role", "actions"];
    } else {
      this.displayedColumns = ["name","actions"]
    }
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectUser(user: User) {
    if (user) {
      this.store$.dispatch(UserActions.userSelected({ user: user }));
    }
  }

  close() {
    this.store$.dispatch(UserActions.closeSelectionDialog())
  }
}
