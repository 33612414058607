 <h2 class="u-flex-full">
   <span>
     <mat-icon>qr_code</mat-icon>QR Code
   </span>
 </h2>
 <div class="barcode_container">
   <img class="barcode" [src]="appraisal?.qrUrl">
 </div>

 <div class="u-flex-full" style="margin-top: 24px;">
   <button *ngIf="appraisal?.qrUrl" mat-raised-button color="primary" type="button"
     (click)="downloadPdf()">Scarica</button>
   <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
 </div>
