import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';


export interface ClientFilters {
  search?: string;
  ids?: number[];
  contractIds?: number[];
}

export interface ClientDTO extends BaseDTO {
  company_name: string;
  company_code: string;
  province: string;
  contact_name: string;
  contact_email: string;

}

export class Client extends Base {
  companyName: string;
  companyCode: string;
  province: string;
  contactName: string;
  contactEmail: string;

  constructor(source: ClientDTO) {
    super(source);
    if (source) {
      this.companyName = source.company_name;
      this.companyCode = source.company_code;
      this.province = source.province;
      this.contactName = source.contact_name;
      this.contactEmail = source.contact_email
    }
  }

  toDTO(): ClientDTO {
    let result: ClientDTO = <ClientDTO>super.toDTO();
    result.company_name = this.companyName;
    result.company_code = this.companyCode;
    result.province = this.province;
    result.contact_name = this.contactName;
    result.contact_email = this.contactEmail
   
    return result;
  }


  static fromFormGroup(formGroup: AbstractControl, original?: Client) {
    const formModel = formGroup.value;
    let client: Client = new Client(null);
    client.companyName = formModel.companyName;
    client.companyCode = formModel.companyCode;
    client.province = formModel.province;
    client.contactName = formModel.contactName;
    client.contactEmail = formModel.contactEmail;
    
    if (original) {
      client.id = original.id;
    }
    return client;
  }

}
