import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ContractDTO } from 'src/app/commons/models/contract.model';
import { ContractSelectionComponent } from 'src/app/modules/shared/contract-selection/contract-selection.component';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelContractService } from '../../commons/services/backend/laravel-contract.service';
import { ContractEditComponent } from '../../modules/home/contracts/contract-edit/contract-edit.component';
import * as ContractActions from '../actions/contract.actions';
import { AppState } from '../reducers';
import { getSelectionDialogId, getContractDialogId, getContractsTableState } from '../selectors/contract.selectors';


@Injectable()
export class ContractEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.saveContractFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadContracts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.loadContracts),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.contractService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              ContractActions.loadContractsCompleted({ contracts: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })),
            catchError(error => {
              return of(ContractActions.loadContractsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.changePage),
      withLatestFrom(this.store$.select(getContractsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes}]) => ContractActions.loadContracts({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.changeSort),
      withLatestFrom(this.store$.select(getContractsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes}]) => ContractActions.loadContracts({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.changeFilters),
      withLatestFrom(this.store$.select(getContractsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => ContractActions.loadContracts({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editContract$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActions.editContract),
    map(({ contract }) => {
      let dialogRef = this.dialog.open(ContractEditComponent, {
        data: {
          contract
        }
      });
      return ContractActions.contractDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveContract$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ContractActions.saveContract),
    mergeMap(({ contract }) =>
      this.contractService.upsert(contract.toDTO())
        .pipe(
          map(result =>
            ContractActions.saveContractCompleted({ contract: result })
          ),
          catchError(error => of(ContractActions.saveContractFailed({ error })))
        )
    )
  )
);

onSaveCompleted$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ContractActions.saveContractCompleted),
    map(action => action.contract),
    tap(contract => this.alertService.showConfirmMessage(`Contratto ${contract.contract_code}  salvato con successo`)),
    map(() => ContractActions.closeContractDialog())
  )
);
   

  deleteContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.deleteContract),
      switchMap(({ contract }) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare il contratto ${contract.contract_code}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.contractService.delete(contract.id)
                  .pipe(
                    map(() => ContractActions.deleteContractCompleted({ contract })),
                    catchError(error => of(ContractActions.deleteContractFailed({ error })))
                  )

                : of(ContractActions.deleteContractCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.deleteContractCompleted),
      tap(({ contract }) => this.alertService.showConfirmMessage(`Contratto ${contract.contract_code} eliminato con successo`)),
      map(() => ContractActions.closeContractDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.closeContractDialog),
      withLatestFrom(this.store$.select(getContractDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.saveContractCompleted),
      withLatestFrom(this.store$.select(getContractsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ContractActions.loadContracts({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.deleteContractCompleted),
      withLatestFrom(this.store$.select(getContractsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ContractActions.loadContracts({ page: currentPage, perPage, order, direction, filters,includes }))
    )
  );

  selectContract$ = createEffect(() => this.actions$.pipe(
    ofType(ContractActions.selectContract),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(ContractSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return ContractActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );

  contractsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.contractSelected),
      map(() => ContractActions.closeSelectionDialog())
    ))

  setFilters$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ContractActions.contractSelected),
    map(({contract}) => ContractActions.changeFilters({filters: {ids: contract.clients.map(c => c.id)}}))
  ))

  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private contractService: LaravelContractService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}
