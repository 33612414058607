import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, forwardRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Client, ClientFilters } from 'src/app/commons/models/client.model';
import * as ClientActions from 'src/app/store/actions/client.actions';
import { AppState } from 'src/app/store/reducers';


export const CLIENTS_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => ClientsFieldComponent),
};

@Component({
  selector: "perizie-clients-field",
  templateUrl: "./clients-field.component.html",
  styleUrls: ["./clients-field.component.scss"],
  providers: [CLIENTS_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class ClientsFieldComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<Client[]> {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  private unsubscribe$ = new Subject<void>();

  private _value: Client[] = [];
  private _disabled = false;

  private _required = false;
  errorState: boolean; //TODO
  focused: boolean; //TODO
  controlType?: string = "client-selection-field";
  autofilled?: boolean;
  clientAriaDescribedBy?: string;

  @Input()
  clientFilters: ClientFilters;

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    @Optional() @Self() public ngControl: NgControl,
    private fb: FormBuilder
  ) {
    this.actions$
      .pipe(
        ofType(ClientActions.clientSelected),
        takeUntil(this.unsubscribe$),
        map(({ client }) => (client ? new Client(client) : null))
      )
      .subscribe((client) => {
        // this.value = [] ;
        this.value.push(client)
        this.notifyValueChange();
      });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  removeClient(index: number) {
    this.value.splice(index, 1);
    this.notifyValueChange();
  }

  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    //TODO
  }

  onContainerClick(event: MouseEvent): void {
    //mrosetti - Nothing to do so far
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectClient() {

    this.clientFilters = {
      ids: this.value.map((c) => c.id),
    };

    this.store$.dispatch(
      ClientActions.selectClient({ filters: this.clientFilters })
    );
  }

  clearClient() {
    this.value = null;
  }
  @Input()
  set value(value: Client[]) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): Client[] {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: Client[]): void {

    this.value = [];
    if (obj) {
      this.value.push(...obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
