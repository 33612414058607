
  <mat-chip-list #chipList>
    <mat-chip color="primary" *ngFor="let user of value; let i = index;" [removable]="true"
      [selectable]="false" (removed)="removeUser(i)">
      {{user.name}}
      <mat-icon *ngIf="currentUser?.role!='supplier'" matChipRemove>cancel</mat-icon>
  
    </mat-chip>
    <button *ngIf="currentUser?.role!='supplier'" class="add" type="button" style="margin: 5px" matTooltip="Aggiungi consulente" (click)="selectUser()" mat-icon-button
    mat-raised-button>
  <mat-icon>add</mat-icon>
  </button>

  

