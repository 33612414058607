<h2 class="u-flex-full">
  <span>
    <mat-icon>history</mat-icon>Storico Perizia
  </span>
</h2>
<div class="mat-elevation-z8" style="margin: 0 15px;">
  <perizie-appraisal-history-list [appraisalsHistory]="appraisalsHistory" [defaultFilters]="defaultFilters" [total]="total"
    (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)" (onFilterChange)="filtersChange($event)"
   ></perizie-appraisal-history-list>
</div>
<div style="margin-top: 24px;float: right;">
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</div>

