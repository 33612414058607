import { createSelector } from '@ngrx/store';

import { selectAppraisalState } from '../reducers';
import { AppraisalState } from '../reducers/appraisal.reducer';

// Appraisal Selectors

export const getAppraisalsTableState = createSelector(
  selectAppraisalState,
  (state: AppraisalState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getAppraisals = createSelector(
  selectAppraisalState,
  (state: AppraisalState) => state.list
);

export const getTotalAppraisals = createSelector(
  getAppraisalsTableState,
  (tableState) => tableState.total
);

export const getAppraisalsCurrentPage = createSelector(
  getAppraisalsTableState,
  (tableState) => tableState.currentPage
);

export const getAppraisalsPerPage = createSelector(
  getAppraisalsTableState,
  (tableState) => tableState.perPage
);

export const getAppraisalsOrder = createSelector(
  getAppraisalsTableState,
  (tableState) => tableState.order
);

export const getAppraisalsDirection = createSelector(
  getAppraisalsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getAppraisalsTableState,
  (tableState) => tableState.filters
);

export const getAppraisalDialogId = createSelector(
  selectAppraisalState,
  (state: AppraisalState) => state.dialogId
);

export const getSelectionDialogId = createSelector(
  selectAppraisalState,
  (state: AppraisalState) => state.selectionDialogId
);
export const getHistoryDialogId = createSelector(
  selectAppraisalState,
  (state: AppraisalState) => state.historyDialogId
);
export const getFilterDialogId = createSelector(
  selectAppraisalState,
  (state: AppraisalState) => state.filterDialogId
);
export const getStatusDialogId = createSelector(
  selectAppraisalState,
  (state: AppraisalState) => state.statusDialogId
);
export const getBarcodeDialogId = createSelector(
  selectAppraisalState,
  (state: AppraisalState) => state.barcodeDialogId
);
export const getScanDialogId = createSelector(
  selectAppraisalState,
  (state: AppraisalState) => state.scanDialogId
);
