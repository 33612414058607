<perizie-user-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</perizie-user-filters>
<mat-card class="u-center-text" *ngIf="!(users | async).length">
  <h4>Nessun utente trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addUser()">Aggiungi</button>
</mat-card>
<div [hidden]="!(users | async).length">
  <table mat-table matSort [dataSource]="users">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id"> ID </th>
      <td mat-cell *matCellDef="let user"> {{user.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let user">
        {{user.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let user">
        {{user.email}}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef> Ruolo </th>
      <td mat-cell *matCellDef="let user">
        {{user.role | userRole }}
        <div *ngIf="user.department">
          Reparto - <strong>{{user.department.departmentName}}</strong>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation();addUser()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let user">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editUser(user)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectUser(user)">Seleziona</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
