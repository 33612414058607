<div class="attachment-upload" style="margin-top: 24px;">
  <span *ngIf="file">
    Nuovo allegato: {{file.name}}
  </span>
  <button *ngIf="file" mat-icon-button type="button" (click)="delete()">
    <mat-icon class="sidenav-icon">close</mat-icon>
  </button>
  <input #fileInput type="file" style="display: none" accept=".pdf,.docx,.csv" (change)="onFileChange($event)"
  (click)="fileInput.value = null" value="">
  <input #imageInput type="file"  accept="image/*" capture style="display: none" (change)="onImageChange($event)"
  (click)="imageInput.value = null" value="" />

    <button *ngIf="url && !file" mat-stroked-button color="primary" class="attachment"
    (click)="downloadFile()">Vedi allegato<mat-icon class="download-icon">get_app</mat-icon></button>
    <span *showFor="['admin','consultant']">
      <button  class="attachment margin" mat-stroked-button color="primary" *ngIf="!fileUrl && !file" type="button" (click)="fileInput.click()">
        {{!!url ? 'Cambia allegato' : 'Allega PDF'  }}<mat-icon class="upload-icon">publish</mat-icon>
      </button>
      <button class="attachment" mat-stroked-button color="primary" *ngIf="!fileUrl && !file" type="button" (click)="imageInput.click()">
        {{!!url ? 'Cambia immagine' : 'Allega immagine'  }}<mat-icon class="upload-icon">publish</mat-icon>
      </button>
     </span>
    <!-- <span *showFor="['admin','consultant']">
      <button class="attachment" mat-stroked-button color="primary" *ngIf="!imageUrl && !image" type="button" (click)="imageInput.click()">
        {{!!url ? 'Cambia immagine' : 'Allega immagine'  }}<mat-icon class="upload-icon">publish</mat-icon>
      </button>
    </span>  -->
</div>

