
import { ThrowStmt } from "@angular/compiler";
import { Component, Inject, OnInit, Optional } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { filter, map, takeUntil, withLatestFrom } from "rxjs/operators";
import {
  Appraisal,
  AppraisalState,
} from "src/app/commons/models/appraisal.model";
import { User, UserFilters } from "src/app/commons/models/user.model";
import { AlertService } from "src/app/commons/services/alert.service";
import * as AppraisalActions from "src/app/store/actions/appraisal.actions";
import * as UserActions from "src/app/store/actions/user.actions";
import { AppState } from "src/app/store/reducers";
import * as UserSelectors from 'src/app/store/selectors/user.selectors';

@Component({
  selector: "app-appraisal-mobile-edit",
  templateUrl: "./appraisal-mobile-edit.component.html",
  styleUrls: ["./appraisal-mobile-edit.component.scss"],
})
export class AppraisalMobileEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _appraisal: Appraisal;
  consultants: User[] = [];
  appraisals: Observable<Appraisal[]>;
  statuses: AppraisalState[] = [
    "under_commitment",
    "under_process",
    "complete",
    "delivered_to_golden_group",
    "delivered_to_consultant",
    "delivered_to_client",
  ];
  consultantFilter: UserFilters = {
    roles: ["consultant"],
  }

  selectedUser: User;
  userFilters: UserFilters;


  statusForm: FormGroup;
  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: FormBuilder,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.appraisal = this.data.appraisal;
    }
    if (this.appraisal) {
      this.consultants = [...this.appraisal.consultants];
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      article: this.appraisal.contract.article,
      client: this.appraisal.client,
      contract: this.appraisal.contract,
      supplier: this.appraisal.supplier,
      consultants: this.appraisal.consultants,
      consultantInCharge: [""],
      createdAt: this.appraisal.createdAt,
      technician: this.appraisal.technician,
      toNotify: this.appraisal.toNotify,
      amount: this.appraisal.amount,
      state: [""],
      confirmation: [""],
      
    };

    this.statusForm = this.fb.group(group);

    this.statusForm.get("state").valueChanges.subscribe((value) => {
      if (value == "delivered_to_client") {
        this.statusForm.get("confirmation").setValidators(Validators.required);
      }
    });
  }

  stateValue(value: AppraisalState): unknown {
    switch (value) {
      case "under_commitment":
        return "under_process";
      case "under_process":
        return "complete";
      case "complete":
        return "delivered_to_golden_group";
      case "delivered_to_golden_group":
        return "delivered_to_consultant";
      case "delivered_to_consultant":
        return "delivered_to_client";
        case "delivered_to_client":
        return "delivered_to_client";
    }
  }


  private ngOnChanges() {
    if (this.statusForm) {
      this.statusForm.reset();
      this._initFormValues(this.appraisal);
    }
  }

  private _initFormValues(appraisal: Appraisal) {
    if (appraisal) {
      this.statusForm.patchValue({
        state: this.stateValue(this.appraisal.state),
        article: appraisal.contract.article,
        client: appraisal.client,
        contract: appraisal.contract,
        supplier: appraisal.supplier,
        consultants: appraisal.consultants,
        consultantInCharge: appraisal.consultantInCharge,
        createdAt: appraisal.createdAt,
        technician: appraisal.technician,
        toNotify: appraisal.toNotify,
        amount: appraisal.amount,
      });
    }
  }

  get appraisal(): Appraisal {
    return this._appraisal;
  }

  set appraisal(appraisal: Appraisal) {
    this._appraisal = appraisal;
    this.ngOnChanges();
  }

  private _prepareSaveAppraisal(): Appraisal {
    let savingAppraisal: Appraisal = Appraisal.fromFormGroup(
      this.statusForm,
      this.appraisal
    );
    return savingAppraisal;
  }

  save() {
    let unsavedEntity = this._prepareSaveAppraisal();
    this.store$.dispatch(
      AppraisalActions.saveAppraisal({
        appraisal: unsavedEntity,
        attachment: this.statusForm.get("confirmation").value,
      })
    );
    this.close();
  }

  close() {
    this.store$.dispatch(AppraisalActions.closeAppraisalStatusDialog());
  }

  revert() {
    this.ngOnChanges();
  }

  compareUsers(u1: User, u2: User): boolean {
    return User.compare(u1, u2);
  }

  openUserSelection() {
    this.userFilters = {
      ids: this.appraisal
        ? this.appraisal.consultants.map((c) => c.id)
        : this.statusForm.controls["consultants"].value.map((c) => c.id),
      roles: ["consultant"],
    };
    this.store$.dispatch(
      UserActions.selectUser({
        filters: this.userFilters,
        currentFormControlName: "consultantInCharge",
      })
    );
    this.store$
      .pipe(
        select(UserSelectors.getSelectedUser),
        takeUntil(this.unsubscribe$),
        withLatestFrom(
          this.store$.select(UserSelectors.getCurrentFormControlName)
        ),
        filter(
          ([selectedUser, currentFormControlName]) =>
            currentFormControlName == "consultantInCharge" && !!selectedUser
        )
      )
      .subscribe(([selectedUser, _]) => {
        this.selectedUser = selectedUser;
        this.consultants.push(selectedUser);
        this.statusForm.patchValue({ consultantInCharge: selectedUser });
      });
  }

}
