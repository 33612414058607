<div *ngIf="appraisal.externalId">
  <small>Id CRM: {{appraisal.externalId}}</small>
</div>
<header class="header-mobile" mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="appraisal">Modifica perizia: {{ appraisal?.contract?.contractCode }}</span>
    <span *ngIf="!appraisal">Nuova perizia</span>
  </span>
  <span *ngIf="appraisal">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="openBarcodePage()">
        <mat-icon>qr_code</mat-icon>
        Barcode
      </button>
      <button *showFor="['admin']" mat-menu-item (click)="openHistory()">
        <mat-icon>history</mat-icon>
        Storico
      </button>
      <ng-container *showFor="['admin']">
        <mat-divider></mat-divider>
        <button *ngIf="appraisal.archived" mat-menu-item (click)="restoreAppraisal()">
          <mat-icon>unarchive</mat-icon>Ripristina
        </button>
        <button mat-menu-item (click)="deleteAppraisal()">
          <ng-container *ngIf="!appraisal.archived">
            <mat-icon>archive</mat-icon>Archivia
          </ng-container>
          <ng-container *ngIf="appraisal.archived">
            <mat-icon>cancel</mat-icon>Elimina
          </ng-container>
        </button>
      </ng-container>
      <div *showFor="['supplier']">
        <button *ngIf="appraisal.state == 'under_commitment'" mat-menu-item (click)="statusUnderProcess()">
          <mat-icon>exit_to_app</mat-icon>Stato In lavorazione
        </button>
        <button *ngIf="appraisal.state == 'under_process'" mat-menu-item (click)="statusComplete()">
          <mat-icon>exit_to_app</mat-icon>Stato Completata
        </button>

      </div>
    </mat-menu>
  </span>
</header>
<mat-dialog-content class="dialog-width">
  <form [formGroup]="appraisalForm" novalidate>
    <div class="u-flex-full">
      <mat-checkbox formControlName="blocked">Bloccata</mat-checkbox>
      <mat-checkbox formControlName="cancelled">Annullata</mat-checkbox>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width disable ">
        <mat-label>Stato</mat-label>
        <mat-select formControlName="state" class="readonly-block" [disabled]="currentUser?.role == 'supplier'">
          <mat-option *ngFor="let state of statuses" [value]="state" [ngClass]="state">
            {{state | appraisalStatus}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width disable">
        <input matInput [disabled]="currentUser?.role == 'supplier'" placeholder="Creazione"
          (click)="externalDatePicker.open()" [matDatepicker]="externalDatePicker" formControlName="externalDate">
        <mat-datepicker-toggle *showFor="['admin']" matSuffix [for]="externalDatePicker"></mat-datepicker-toggle>
        <mat-datepicker-toggle *showFor="['supplier']" matPrefix [for]="externalDatePicker"></mat-datepicker-toggle>
        <button matPrefix mat-button type="button" *ngIf="appraisalForm.value['externalDate']" mat-icon-button
          aria-label="Clear" (click)="appraisalForm.patchValue({externalDate: ''})">
          <mat-icon *showFor="['admin']">close</mat-icon>
        </button>
        <mat-datepicker #externalDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Contratto</mat-label>
        <perizie-contract-field formControlName="contract">
        </perizie-contract-field>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width" *ngIf="appraisalForm.value.contract">
        <mat-label>Cliente</mat-label>
        <perizie-client-field formControlName="client">
        </perizie-client-field>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Consulenti</mat-label>
        <perizie-users-field [userFilters]="consultantFilter" formControlName="consultants">
        </perizie-users-field>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Fornitore</mat-label>
        <perizie-user-field [userFilters]="supplierFilter" formControlName="supplier">
        </perizie-user-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width disable"
        *ngIf="appraisalForm?.value?.state == 'delivered_to_consultant' || appraisal?.state == 'delivered_to_consultant'">
        <mat-label>Consulente in carico</mat-label>
        <mat-select formControlName="consultantInCharge" [compareWith]="compareUser"
          [disabled]="currentUser?.role == 'supplier'">
          <mat-option [value]="">Nessuno</mat-option>
          <mat-option *ngFor="let consultant of consultants" [value]="consultant">
            {{ consultant.name }}</mat-option>
          <mat-option (click)="openUserSelection()">
            <mat-icon>open_in_new</mat-icon>Cerca consulente
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="u-flex-full">

      <mat-form-field floatLabel="always" class="u-full-width">
        <input [readonly]="currentUser?.role == 'supplier'" matInput placeholder="Tecnico interno" type="text"
          formControlName="technician">
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Importo</mat-label>
        <input matInput type="number" formControlName="amount">
        <span matPrefix>€&nbsp;</span>
      </mat-form-field>
    </div>

    <mat-form-field floatLabel="always" class="u-full-width">
      <input [readonly]="currentUser?.role == 'supplier'" matInput placeholder="Da notificare" type="text"
        formControlName="toNotify">
      <mat-hint>Indirizzi e-mail separati da virgola</mat-hint>
      <mat-error *ngIf="appraisalForm.controls['toNotify'].errors && appraisalForm.controls['toNotify'].dirty">Campo non
        valido </mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always" class="u-full-width" style="margin-top: 16px;">
      <input matInput placeholder="Note" type="text" formControlName="note">
    </mat-form-field>

    <div class="u-flex-full" *ngIf="appraisalForm.value.state == 'delivered_to_client' ">
      <attachment [url]="appraisal?.confirmation" formControlName="confirmation">
      </attachment>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="appraisalForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="appraisalForm.pristine || !appraisalForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
