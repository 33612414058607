import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, flatMap, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { LaravelAppraisalHistoryService } from 'src/app/commons/services/backend/laravel-appraisal-history.service';
import { AlertService } from '../../commons/services/alert.service';
import * as AppraisalHistoryActions from '../actions/appraisal-history.actions';
import { AppState } from '../reducers';
import { getAppraisalsHistoryTableState } from '../selectors/appraisal-history.selectors';


@Injectable()
export class AppraisalHistoryEffects {

  loadAppraisalsHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalHistoryActions.loadAppraisalsHistory),
      switchMap(({ page, perPage, order, direction, filters, includes}) => {
        return this.appraisalHistoryService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              AppraisalHistoryActions.loadAppraisalsHistoryCompleted({ appraisalsHistory: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })),
            catchError(error => {
              return of(AppraisalHistoryActions.loadAppraisalsHistoryFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalHistoryActions.changePage),
      withLatestFrom(this.store$.select(getAppraisalsHistoryTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters,includes }]) => AppraisalHistoryActions.loadAppraisalsHistory({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalHistoryActions.changeSort),
      withLatestFrom(this.store$.select(getAppraisalsHistoryTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => AppraisalHistoryActions.loadAppraisalsHistory({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters,includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(AppraisalHistoryActions.changeFilters),
      withLatestFrom(this.store$.select(getAppraisalsHistoryTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => AppraisalHistoryActions.loadAppraisalsHistory({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private appraisalHistoryService: LaravelAppraisalHistoryService,
    private alertService: AlertService
  ) { }
}
