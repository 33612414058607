import { createSelector } from '@ngrx/store';

import { selectAppraisalHistoryState } from '../reducers';
import { AppraisalHistoryState } from '../reducers/appraisal-history.reducer';

// Appraisal history Selectors

export const getAppraisalsHistoryTableState = createSelector(
  selectAppraisalHistoryState,
  (state: AppraisalHistoryState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getAppraisalsHistory = createSelector(
  selectAppraisalHistoryState,
  (state: AppraisalHistoryState) => state.list
);

export const getTotalAppraisalsHistory = createSelector(
  getAppraisalsHistoryTableState,
  (tableState) => tableState.total
);

export const getAppraisalsHistoryCurrentPage = createSelector(
  getAppraisalsHistoryTableState,
  (tableState) => tableState.currentPage
);

export const getAppraisalsHistoryPerPage = createSelector(
  getAppraisalsHistoryTableState,
  (tableState) => tableState.perPage
);

export const getAppraisalsHistoryOrder = createSelector(
  getAppraisalsHistoryTableState,
  (tableState) => tableState.order
);

export const getAppraisalsHistoryDirection = createSelector(
  getAppraisalsHistoryTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getAppraisalsHistoryTableState,
  (tableState) => tableState.filters
);


