import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Appraisal, AppraisalState } from 'src/app/commons/models/appraisal.model';
import { User, UserFilters } from 'src/app/commons/models/user.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as AppraisalActions from 'src/app/store/actions/appraisal.actions';
import * as UserActions from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import * as UserSelectors from 'src/app/store/selectors/user.selectors';

@Component({
  selector: "app-appraisal-edit",
  templateUrl: "./appraisal-edit.component.html",
  styleUrls: ["./appraisal-edit.component.scss"],
})
export class AppraisalEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _appraisal: Appraisal;
  currentUser: User;
  appraisals: Observable<Appraisal[]>;
  statuses: AppraisalState[] = [
    "under_commitment",
    "under_process",
    "complete",
    "delivered_to_golden_group",
    "delivered_to_consultant",
    "delivered_to_client",
  ];

  consultantFilter: UserFilters = {
    roles: ["consultant"],
  };
  supplierFilter: UserFilters = {
    roles: ["supplier"],
  };
  consultants: User[] = [];
  selectedUser: User;
  userFilters: UserFilters;

  appraisalForm: FormGroup;
  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store$
      .pipe(
        select(AuthSelectors.getCurrentUser),
        takeUntil(this.unsubscribe$),
        map((dto) => (dto ? new User(dto) : null))
      )
      .subscribe((user) => (this.currentUser = user));
  }

  ngOnInit() {
    if (this.data) {
      this.appraisal = this.data.appraisal;
    }
    if (this.appraisal) {
      this.consultants = [...this.appraisal.consultants];
    }

    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      state: ["", Validators.required],
      article: [""],
      client: ["", Validators.required],
      contract: ["", Validators.required],
      supplier: [""],
      consultants: [""],
      consultantInCharge: [""],
      externalDate: ["", Validators.required],
      technician: [""],
      toNotify: [
        "",
        Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,}(\s*,?\s*)*)+$/),
      ],
      amount: [""],
      confirmation: [""],
      note: [""],
      blocked: [],
      cancelled: []
    };

    this.appraisalForm = this.fb.group(group);

    this.appraisalForm.get("state").valueChanges.subscribe((value) => {
      if (value == "delivered_to_client") {
        this.appraisalForm
          .get("confirmation")
          .setValidators(Validators.required);
      }
    });
  }

  private ngOnChanges() {
    if (this.appraisalForm) {
      this.appraisalForm.reset();
      if (this.appraisal) {
        this._initFormValues(this.appraisal);
      } else {
        this._initFormValues();
      }
    }
  }

  private _initFormValues(appraisal?: Appraisal) {
    this.appraisalForm.patchValue({
      externalDate: new Date(),
    });
    if (appraisal) {
      this.appraisalForm.patchValue({
        state: appraisal.state,
        article: appraisal.contract.article,
        client: appraisal.client,
        contract: appraisal.contract,
        supplier: appraisal.supplier,
        consultants: appraisal.consultants,
        consultantInCharge: appraisal.consultantInCharge,
        externalDate: appraisal.externalDate,
        technician: appraisal.technician,
        toNotify: appraisal.toNotify,
        amount: appraisal.amount,
        confirmation: appraisal.confirmation,
        note: appraisal.note,
        blocked: appraisal.blocked,
        cancelled: appraisal.cancelled
      });
    }
  }

  get appraisal(): Appraisal {
    return this._appraisal;
  }

  set appraisal(appraisal: Appraisal) {
    this._appraisal = appraisal;
    this.ngOnChanges();
  }

  private _prepareSaveAppraisal(): Appraisal {
    let savingAppraisal: Appraisal = Appraisal.fromFormGroup(
      this.appraisalForm,
      this.appraisal
    );
    return savingAppraisal;
  }

  save() {
    let unsavedEntity = this._prepareSaveAppraisal();
    this.store$.dispatch(
      AppraisalActions.saveAppraisal({
        appraisal: unsavedEntity,
        attachment: this.appraisalForm.get("confirmation").value,
      })
    );
    console.log(this.appraisal.qrUrl);

  }

  close() {
    if (this.appraisalForm.pristine) {
      this.store$.dispatch(AppraisalActions.closeAppraisalDialog());
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe((result) => {
          if (result) {
            this.store$.dispatch(AppraisalActions.closeAppraisalDialog());
          }
        });
    }
  }

  statusComplete() {
    this.appraisalForm.patchValue({
      state: 'complete',
    });
    this.save();
  }
  statusUnderProcess() {
    this.appraisalForm.patchValue({
      state: 'under_process',
    });
    this.save();
  }

  restoreAppraisal() {
    if (this.appraisal && this.appraisal.archived) {
      this.store$.dispatch(
        AppraisalActions.restoreAppraisal({ appraisal: this.appraisal.toDTO() })
      );
    }
  }

  deleteAppraisal() {
    if (this.appraisal) {
      this.store$.dispatch(
        AppraisalActions.deleteAppraisal({ appraisal: this.appraisal.toDTO() })
      );
    }
  }
  openHistory() {
    if (this.appraisal) {
      this.store$.dispatch(
        AppraisalActions.openHistory({ appraisal: this.appraisal })
      );
    }
  }
  openBarcodePage() {
    if (this.appraisal) {
      this.store$.dispatch(
        AppraisalActions.openBarcode({ appraisal: this.appraisal })
      );
    }
  }

  revert() {
    this.ngOnChanges();
  }
  compareUser = User.compare;

  openUserSelection() {
    this.userFilters = {
      ids: this.appraisal
        ? this.appraisal.consultants.map((c) => c.id)
        : this.appraisalForm.controls["consultants"].value.map((c) => c.id),
      roles: ["consultant"],
    };
    this.store$.dispatch(
      UserActions.selectUser({
        filters: this.userFilters,
        currentFormControlName: "consultantInCharge",
      })
    );
    this.store$
      .pipe(
        select(UserSelectors.getSelectedUser),
        takeUntil(this.unsubscribe$),
        withLatestFrom(
          this.store$.select(UserSelectors.getCurrentFormControlName)
        ),
        filter(
          ([selectedUser, currentFormControlName]) =>
            currentFormControlName == "consultantInCharge" && !!selectedUser
        )
      )
      .subscribe(([selectedUser, _]) => {
        this.selectedUser = selectedUser;
        this.consultants.push(selectedUser);
        this.appraisalForm.patchValue({ consultantInCharge: selectedUser });
      });
  }
}
