<div [hidden]="!hasDevices">

  <zxing-scanner [torch]="false" [(device)]="currentDevice" (scanSuccess)="handleQrCodeResult($event)"
    [tryHarder]="false" (camerasFound)="onCamerasFound($event)"></zxing-scanner>

  <!-- <div *ngIf="hasPermission == undefined">

		<h1>Waiting for permissions.</h1>

		<blockquote>
		  <h2>If your device does not has cameras, no permissions will be asked.</h2>
		</blockquote>

	  </div> -->

  <!-- <h2 *ngIf="!this.currentDevice">No camera selected.</h2>

	<p>
	  Result:
	  <strong style="margin-right: 16px;">{{ qrResultString }}</strong>
	</p> -->
  <button style="margin-top: 16px;" mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>

</div>
