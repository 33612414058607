<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="contract">Modifica contratto: {{contract.contractCode}}</span>
    <span *ngIf="!contract">Nuovo contratto</span>
  </span>
  <span *ngIf="contract">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteContract()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="contractForm" novalidate>
    <mat-form-field floatLabel="always" class="u-full-width">
      <mat-label>Clienti</mat-label>
      <perizie-clients-field formControlName="clients">
      </perizie-clients-field>
    </mat-form-field>
  
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Protocollo" formControlName="protocolSub" type="text" required>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Codice contratto" formControlName="contractCode" type="text">
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <mat-label>Articolo</mat-label>
      <input type="text"
      placeholder="Schegli articolo"
      aria-label="Number"
      matInput
      formControlName="article"
      [matAutocomplete]="auto"
      required>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let article of filteredArticles | async" [value]="article">
      {{article}}
    </mat-option>
  </mat-autocomplete>
    </mat-form-field>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Commerciale" formControlName="commercialEmail" type="email">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Tecnico" formControlName="technicianEmail" type="email">
      </mat-form-field>
      </div>

  </form>
  <!-- {{contractForm.value | json}} -->


</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="contractForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="contractForm.pristine || !contractForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
