import * as fromRouter from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromAuth from './auth.reducers';
import * as fromUser from './user.reducer';
import * as fromClient from './client.reducer';
import * as fromContract from './contract.reducer';
import * as fromAppraisal from './appraisal.reducer';
import * as fromAppraisalHistory from './appraisal-history.reducer';

export interface AppState {
  router: fromRouter.RouterReducerState<any>;
  auth: fromAuth.AuthState;
  user: fromUser.UserState;
  client: fromClient.ClientState;
  contract: fromContract.ContractState;
  appraisal: fromAppraisal.AppraisalState;
  appraisalHistory:  fromAppraisalHistory.AppraisalHistoryState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: fromRouter.routerReducer,
  auth: fromAuth.reducer,
  user: fromUser.reducer,
  client: fromClient.reducer,
  contract: fromContract.reducer,
  appraisal: fromAppraisal.reducer,
  appraisalHistory: fromAppraisalHistory.reducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [{ auth: ["token"] }],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, fromAuth.clearState];

export const selectRouter = createFeatureSelector<
  AppState,
  fromRouter.RouterReducerState<any>
>("router");
export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  "auth"
);
export const selectUserState = createFeatureSelector<fromUser.UserState>(
  "user"
);
export const selectClientState = createFeatureSelector<fromClient.ClientState>(
  "client"
);
export const selectContractState = createFeatureSelector<fromContract.ContractState>(
  "contract"
);
export const selectAppraisalState = createFeatureSelector<fromAppraisal.AppraisalState>(
  "appraisal"
);
export const selectAppraisalHistoryState = createFeatureSelector<fromAppraisalHistory.AppraisalHistoryState>(
  "appraisalHistory"
);

//Route selectors
const {
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectRouteId = selectRouteParam("id");
export const selectStatus = selectQueryParam("status");
