<h2 class="u-flex-full">
  <span>
    {{ appraisal?.contract?.contractCode}} del {{ appraisal?.createdAt |date: 'dd-MM-yyyy'}}
  </span>
</h2>
<mat-dialog-content>

  <mat-list class="list-mobile">
    <mat-list-item >
      <p class="item" matLine>
        Client:  {{appraisal?.client?.companyName}}
      </p>
      <p class="item" matLine>
      Contratto: {{appraisal?.contract?.contractCode}} 
      </p>
      <p class="item" matLine>
       Protocollo: {{appraisal?.contract?.protocolSub }} 
      </p>
      <p class="item" matLine>
        Articolo: {{appraisal?.contract?.article }} 
      </p>
      <p *ngIf="(appraisal?.consultantInCharge) && (appraisal?.state == 'delivered_to_consultant' || appraisal?.state == 'delivered_to_client') " class="item" matLine>
        Consulente in carico: {{appraisal?.consultantInCharge.name}} 
      </p>
      <p class="item" matLine>
        Fornitore: {{appraisal?.supplier?.name }} 
      </p>
      <p class="item" matLine>
        Tecnico interno: {{appraisal?.technician }} 
      </p>
    </mat-list-item>
  </mat-list>

   <form [formGroup]="statusForm" novalidate>
    <mat-form-field floatLabel="always" class="u-full-width" *ngIf="statusForm.value.state == 'delivered_to_consultant'">
      <mat-label>Consulente in carico</mat-label>
      <mat-select formControlName="consultantInCharge" [compareWith]="compareUsers">
        <mat-option [value]="">Nessuno</mat-option> 
        <mat-option  *ngFor="let consultant of consultants" [value]="consultant">
          {{ consultant.name }}</mat-option>
          <mat-option *ngIf="selectedUser" [value]="selectedUser">{{selectedUser.name}}</mat-option>
          <mat-option (click)="openUserSelection()"><mat-icon>open_in_new</mat-icon>Cerca consulente</mat-option>
      </mat-select> 

    </mat-form-field>
   
    <attachment *ngIf="statusForm.value.state == 'delivered_to_client' || appraisal.state == 'delivered_to_client'" [url]="appraisal?.confirmation" formControlName="confirmation">
    </attachment>

  </form> 
</mat-dialog-content>
  <mat-dialog-actions class="u-flex-full mobile" style="margin-top: 16px;">
    <button  *ngIf="appraisal.state == 'under_commitment'"mat-raised-button color="primary" style="margin: 18px 0;"(click)="save()">In lavorazione</button>
    <button  *ngIf="appraisal.state == 'under_process'"mat-raised-button color="primary" style="margin: 16px 0;" (click)="save()">Completata</button>
    <div *ngIf="appraisal.state == 'complete'">
      <button mat-raised-button color="primary" *showFor="['admin']" style="margin: 16px 0;" (click)="save()">Consegnata al Golden Group</button>
    </div>
    <button *ngIf="appraisal.state == 'delivered_to_golden_group'"mat-raised-button color="primary" style="margin: 16px 0;" (click)="save()">Consegnata al consulente</button>
    <div *ngIf="appraisal.state == 'delivered_to_consultant'">
      <button  [disabled]="statusForm.pristine || !statusForm.valid" mat-raised-button color="primary" *showFor="['admin','consultant']" style="margin: 16px 0;" (click)="save()">Consegna al cliente</button>
    </div>
    <div *ngIf="appraisal.state == 'delivered_to_client'">
      <button  [disabled]="statusForm.pristine || !statusForm.valid" *showFor="['admin','consultant']" mat-raised-button color="primary" style="margin: 16px 0;" (click)="save()">Salva</button>
    </div>
    <button class="close-mobile" mat-raised-button color="basic" type="button" style="margin: 18px 0;"(click)="close()">Chiudi</button>
  </mat-dialog-actions>
   
    
  
