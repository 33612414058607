import * as dayjs from 'dayjs';

dayjs.extend(require('dayjs/plugin/customParseFormat'))

const DTO_DATE_FORMAT = "DD-MM-YYYY";
const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const BACKEND_DATE_FORMAT_WITH_TIME = "YYYY-MM-DD HH:mm:ss";

export function formatDateForBackend(date: Date, includeTime: boolean = false) {
  if (date) {
    return dayjs(date).format(
      includeTime ? BACKEND_DATE_FORMAT_WITH_TIME : BACKEND_DATE_FORMAT
    );
  }
  return null;
}

export function dateFromDTO(value: string): Date {
  if (value) {
    return dayjs(value, DTO_DATE_FORMAT).toDate();
  }
  return null;
}
