import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Contract } from 'src/app/commons/models/contract.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import { ContractsColumn } from 'src/app/modules/shared/contract-list/contract-list.component';
import * as ContractActions from 'src/app/store/actions/contract.actions';
import { AppState } from 'src/app/store/reducers';
import * as ContractSelectors from 'src/app/store/selectors/contract.selectors';

import { ContractFilters } from './../../../commons/models/contract.model';



@Component({
  selector: 'perizie-contracts-selection',
  templateUrl: './contract-selection.component.html',
  styleUrls: ['./contract-selection.component.scss']
})
export class ContractSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  contracts: Observable<Contract[]>;
  filters: Observable<ContractFilters>;

  @Input()
  defaultFilters: ContractFilters = {
    search: "",
  }

  displayedColumns: ContractsColumn[] = ["client", "protocolSub", "contractCode", "article", "commercialEmail", "technicianEmail", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.contracts = this.store$.pipe(select(ContractSelectors.getContracts), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new Contract(dto)) : null));
    this.total = this.store$.pipe(select(ContractSelectors.getTotalContracts), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(ContractSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(ContractActions.loadContracts(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters, includes:["clients"] }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(ContractActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(ContractActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: ContractFilters) {
    this.store$.dispatch(ContractActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectContract(contract: Contract) {
    if (contract) {
      this.store$.dispatch(ContractActions.contractSelected({ contract: contract.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(ContractActions.closeSelectionDialog())
  }
}
