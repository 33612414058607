import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Client } from 'src/app/commons/models/client.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as ClientActions from 'src/app/store/actions/client.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _client: Client;
  clients: Observable<Client[]>;

 
  clientForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }

  ngOnInit() {
    if (this.data) {
      this.client = this.data.client;
    }
    this._createForm();
    this.ngOnChanges();
 
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      companyName: ["", Validators.required],
      companyCode: ["",Validators.required],
      province: [""],
      contactName: [""],
      contactEmail: ["", Validators.email]
   
    }
  
    this.clientForm = this.fb.group(group);
   
  }

  private ngOnChanges() {
    if (this.clientForm) {
      this.clientForm.reset();
      if (this.client) {
        this._initFormValues(this.client);
      }
    }

  }

  private _initFormValues(client: Client) {
    if (client) {
      this.clientForm.patchValue({
        companyName: client.companyName,
        companyCode: client.companyCode,
        province: client.province,
        contactName: client.contactName,
        contactEmail: client.contactEmail
       
      });
    }
  }

  get client(): Client {
    return this._client;
  }

  set client(client: Client) {
    this._client = client;
    this.ngOnChanges();
  }

  private _prepareSaveClient(): Client {
    let savingClient: Client = Client.fromFormGroup(this.clientForm, this.client);
    return savingClient;
  }

  save() {
    let unsavedEntity = this._prepareSaveClient();
    this.store$.dispatch(ClientActions.saveClient({ client: unsavedEntity }));
  }

  close() {
    if (this.clientForm.pristine) {
      this.store$.dispatch(ClientActions.closeClientDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(ClientActions.closeClientDialog())
          }
        });
    }
  }

  deleteClient() {
    if (this.client) {
      this.store$.dispatch(ClientActions.deleteClient({ client: this.client.toDTO() }))
    }
  }


  revert() {
    this.ngOnChanges();
  }
}
