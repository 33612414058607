import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Client, ClientDTO } from './client.model';

export interface ContractFilters {
  search?: string;
  ids?: number[];
}

export interface ContractDTO extends BaseDTO {
  clients: ClientDTO[];
  protocol_sub: string;
  contract_code: string;
  article: string;
  commercial_email: string;
  technician_email: string;

}

export class Contract extends Base {
  clients: Client[];
  protocolSub: string;
  contractCode: string;
  article: string;
  commercialEmail: string;
  technicianEmail: string;

  constructor(source: ContractDTO) {
    super(source);
    if (source) {
      if (source.clients) {
        this.clients = source.clients.map((dto) => new Client(dto));
        this.addLoadedRelation('clients');
      }
      this.protocolSub = source.protocol_sub;
      this.contractCode = source.contract_code;
      this.article = source.article;
      this.commercialEmail = source.commercial_email;
      this.technicianEmail = source.technician_email;
    }
  }

  toDTO(): ContractDTO {
    let result: ContractDTO = <ContractDTO>super.toDTO();
    result.clients = this.clients
    ? this.clients.map((client) => client.toDTO())
    : null;
    result.protocol_sub = this.protocolSub;
    result.contract_code = this.contractCode;
    result.article = this.article;
    result.commercial_email = this.commercialEmail;
    result.technician_email = this.technicianEmail;

    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Contract) {
    const formModel = formGroup.value;
    let contract: Contract = new Contract(null);
    contract.clients = formModel.clients;
    contract.protocolSub = formModel.protocolSub;
    contract.contractCode = formModel.contractCode;
    contract.article = formModel.article;
    contract.commercialEmail = formModel.commercialEmail;
    contract.technicianEmail = formModel.technicianEmail
    
    
    if (original) {
      contract.id = original.id;
    }
    return contract;
  }

}
