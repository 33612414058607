import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { AppraisalHistoryDTO, AppraisalHistoryFilters } from '../../models/appraisal-history.model';


@Injectable({
  providedIn: "root"
})
export class LaravelAppraisalHistoryService {


  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/appraisals_history"
    };
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters?: AppraisalHistoryFilters,
    includes?: string[]
  ): Observable<ListResultDTO<AppraisalHistoryDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.initialState) params["initial_states[]"] = filters.initialState;
      if (filters.finalState) params["final_states[]"] = filters.finalState;
      if (filters.user) params["user_id"] = filters.user.id;
      if (filters.creationStart) params["from"] = formatDateForBackend(filters.creationStart);
      if (filters.creationEnd) params["to"] = formatDateForBackend(filters.creationEnd);
      if (filters.appraisal) params["appraisal_id"] = filters.appraisal.id;
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<AppraisalHistoryDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<AppraisalHistoryDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }


}








