import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AlertService } from 'src/app/commons/services/alert.service';
import { AppState } from 'src/app/store/reducers';
import * as AppraisalActions from '../../../store/actions/appraisal.actions';

import { MatFormFieldControl } from '@angular/material/form-field';

export const ATTACHMENT_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => AttachmentComponent),
}

@Component({
  selector: 'attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  providers: [
    ATTACHMENT_FIELD_MAT_FORM_FIELD_CONTROL,
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: AttachmentComponent,
      multi: true
    }
  ],
  host: {
    '[class.floating]': 'shouldLabelFloat',
    '[id]': 'id',
  }
})
export class AttachmentComponent implements ControlValueAccessor {
  fileUrl: string;
  imageUrl: string;
  onChange: Function;
  file: File | null = null;
  image: File | null = null;
  disabled: boolean = false;
  
  @Input()
  url: string;
 

  @Input()
  deleteConfirmMessage: string;

  @HostListener("change", ["$event.target.files"]) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.file = file;
    this.image = file
    this.onChange(this.file);
    this.onChange(this.image);
  }

  constructor(private store$: Store<AppState>, private host: ElementRef<HTMLInputElement>) { }

  ngOnInit() { }

  writeValue(value) {
    // clear file input
    this.host.nativeElement.value = "";
    if(this.file) {  
      this.file = value;
    } else {
      this.image = value
    }
  
    if (value) {
      if (typeof value == "string") {
        if(this.fileUrl) {
          this.fileUrl = value;
        } else {
          this.imageUrl = value;
        }
       
      } 
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) { }

  onFileChange(event) {
    console.log("file");
    
    if (event.target.files && event.target.files.length) {
      this.registerOnTouched;
    }
  }

  onImageChange(event) {
    if (event.target.files && event.target.files.length) {
      this.registerOnTouched;
    }
  }

   delete() {
    this.file = null;
    this.fileUrl = null;
    this.image = null;
    this.imageUrl = null;
    this.onChange(null);
  }

  downloadFile() {
    console.log(this.url);
    
      this.store$.dispatch(AppraisalActions.downloadAttachment({fileName: this.url}))
    }
  }


