import { createAction, props } from '@ngrx/store';

import { Contract, ContractDTO, ContractFilters } from '../../commons/models/contract.model';

export const loadContracts = createAction('[Contracts] Load contracts', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ContractFilters, includes?: string[] }>());
export const loadContractsCompleted = createAction('[Contracts] Load contracts Completed', props<{ contracts: ContractDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ContractFilters,includes?: string[]  }>());
export const loadContractsFailed = createAction('[Contracts] Load contracts Failed', props<{ error: any }>());

export const changePage = createAction('[Contracts] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Contracts] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Contracts] Change filters', props<{ filters: ContractFilters }>());

export const editContract = createAction('[Contracts] Edit contract', props<{ contract: Contract }>());
export const contractDialogOpened = createAction('[Contracts] Detail dialog opened', props<{ dialogId: string }>());
export const closeContractDialog = createAction('[Contracts] Close detail dialog');

export const saveContract = createAction('[Contracts] Save contract', props<{ contract: Contract }>());
export const saveContractCompleted = createAction('[Contracts] Save contract completed', props<{ contract: ContractDTO }>());
export const saveContractFailed = createAction('[Contracts] Save contract failed', props<{ error: any }>());

export const deleteContract = createAction('[Contracts] Delete contract', props<{ contract: ContractDTO }>());
export const deleteContractCompleted = createAction('[Contracts] Delete contract completed', props<{ contract: ContractDTO }>());
export const deleteContractCancelled = createAction('[Contracts] Delete contract cancelled');
export const deleteContractFailed = createAction('[Contracts] Delete contract failed', props<{ error: any }>());



export const selectContract = createAction('[Contracts] Select contract', props<{ filters?: ContractFilters }>());
export const selectionDialogOpened = createAction('[Contracts] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Contracts] Close selection dialog');
export const contractSelected = createAction('[Contracts] Contract selected', props<{ contract: ContractDTO }>());
