import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AppraisalFilters, AppraisalState } from 'src/app/commons/models/appraisal.model';
import { User, UserFilters } from 'src/app/commons/models/user.model';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import * as AppraisalActions from '../../../store/actions/appraisal.actions';

@Component({
  selector: "perizie-appraisal-filters",
  templateUrl: "./appraisal-filters.component.html",
  styleUrls: ["./appraisal-filters.component.scss"],
})
export class AppraisalFiltersComponent implements OnInit {
  statuses: AppraisalState[] = [
    "under_commitment",
    "under_process",
    "complete",
    "delivered_to_golden_group",
    "delivered_to_consultant",
    "delivered_to_client",
  ];

  consultantFilter: UserFilters = {
    roles: ["consultant"],
  };
  supplierFilter: UserFilters = {
    roles: ["supplier"],
  };

  articles = [
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2016",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2017",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2018",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2019",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2020",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2021",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2022",
    "Credito d'Imposta per attività di Ricerca e Sviluppo 2023",
    "L. 311/2004 - Anno 2019",
    "L. 311/2004 - Anno 2020",
    "L. 311/2004 - Anno 2021",
    "L. 311/2004 - Anno 2022",
    "L. 311/2004 - Anno 2023",
    "L. 311/2004_Detassazione costi personale R&S",
    "L. 311/2004 - Detassazione dei Costi di Personale dedicato all’Attività di R&S",
    "Legge di Bilancio 2017, art. 1, commi 9-13: Iper-ammortamento",
    "Naz_L. 160/2019 - Credito d’Imposta Beni Strumentali (ex Iper e Super ammortamento)",
    "Naz_L. 178/2020 - Credito d’Imposta Beni Strumentali 2021-2022 (ex Iper e Super ammortamento)",
    "Naz_DL 104/2020 - Rivalutazione Beni d'Impresa",
    "ATTESTAZIONI BENI 4.0",
  ];

  @Output()
  onFilter: EventEmitter<AppraisalFilters> = new EventEmitter();

  private _defaultFilters: AppraisalFilters;
  filters: AppraisalFilters;
  filtersForm: FormGroup;
  currentUser: User;
  filteredArticles: Observable<string[]>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store$
      .pipe(
        select(AuthSelectors.getCurrentUser),
        takeUntil(this.unsubscribe$),
        map((dto) => (dto ? new User(dto) : null))
      )
      .subscribe((user) => (this.currentUser = user));
    this._createForm();
  }

  ngOnInit() {
    if (this.data) {
      this.defaultFilters = this.data.filters;
    }

    this.filteredArticles = this.filtersForm.get("article").valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );
  }

  private _createForm() {
    let group = {
      id: [""],
      state: [
        [
          "Impegno di lavorazione",
          "In lavorazione",
          "Completata",
          "Consegnata a Golden Group",
          "Consegnata al consulente",
          "Consegnata al cliente",
        ],
      ],
      client: [""],
      consultant: [""],
      supplier: [""],
      consultantInCharge: [""],
      province: [""],
      article: [""],
      creationStart: [""],
      creationEnd: [""],
      updateStart: [""],
      updateEnd: [""],
      externalId: [""],
      blocked: [],
      cancelled: [],
      includeArchived: []
    };

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    if (this.currentUser.role == "admin") {
      this.onFilter.emit(this._getAppraisalFilters());
    } else {
      this.store$.dispatch(
        AppraisalActions.changeFilters({ filters: this._getAppraisalFilters() })
      );
      this.close();
    }
  }
  close() {
    this.store$.dispatch(AppraisalActions.closeAppraisalFilterDialog());
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _getAppraisalFilters(): AppraisalFilters {
    let filters: AppraisalFilters = {};
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.id = values.id;
      filters.state = values.state;
      filters.client = values.client;
      filters.consultant = values.consultant;
      filters.supplier = values.supplier;
      filters.consultantInCharge = values.consultantInCharge;
      filters.province = values.province;
      filters.article = values.article;
      filters.creationStart = values.creationStart;
      filters.creationEnd = values.creationEnd;
      filters.updateStart = values.updateStart;
      filters.updateEnd = values.updateEnd;
      filters.externalId = values.externalId;
      filters.blocked = values.blocked;
      filters.cancelled = values.cancelled;
      filters.includeArchived = values.includeArchived;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      id: "",
      state: "",
      client: "",
      consultant: "",
      supplier: "",
      consultantInCharge: "",
      province: "",
      article: "",
      creationStart: "",
      creationEnd: "",
      updateStart: "",
      updateEnd: "",
      externalId: "",
      blocked: "",
      cancelled: "",
      includeArchived: false
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): AppraisalFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: AppraisalFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          id: this.defaultFilters.id,
          state: this.defaultFilters.state,
          client: this.defaultFilters.client,
          consultant: this.defaultFilters.consultant,
          supplier: this.defaultFilters.supplier,
          consultantInCharge: this.defaultFilters.consultantInCharge,
          province: this.defaultFilters.province,
          article: this.defaultFilters.article,
          creationStart: this.defaultFilters.creationStart,
          creationEnd: this.defaultFilters.creationEnd,
          updateStart: this.defaultFilters.updateStart,
          updateEnd: this.defaultFilters.updateEnd,
          externalId: this.defaultFilters.externalId,
          blocked: this.defaultFilters.blocked,
          cancelled: this.defaultFilters.cancelled,
          includeArchived: this.defaultFilters.includeArchived
        });
      }
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.articles.filter(article => article.toLowerCase().includes(filterValue));
  }
}
