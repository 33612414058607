import { closeHistoryDialog } from "../../../store/actions/appraisal.actions";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import {
  AppraisalHistory,
  AppraisalHistoryFilters,
} from "src/app/commons/models/appraisal-history.model";
import { AppState } from "src/app/store/reducers";
import * as AppraisalHistorySelectors from "src/app/store/selectors/appraisal-history.selectors";

import {
  Appraisal,
  AppraisalFilters,
} from "../../../commons/models/appraisal.model";
import { PAGE_SIZE_OPTIONS } from "../../../helpers/table.helper";
import * as AppraisalHistoryActions from "../../../store/actions/appraisal-history.actions";
import * as AppraisalActions from "../../../store/actions/appraisal.actions";
import { Optional } from "@angular/core";
import { Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-appraisal-history",
  templateUrl: "./appraisal-history.component.html",
  styleUrls: ["./appraisal-history.component.scss"],
})
export class AppraisalHistoryComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  appraisalsHistory: Observable<AppraisalHistory[]>;
  filters: Observable<AppraisalHistoryFilters>;

  defaultFilters: AppraisalHistoryFilters = {
    initialState: [
      "under_commitment",
      "under_process",
      "complete",
      "delivered_to_golden_group",
      "delivered_to_consultant",
      "delivered_to_client",
    ],
    finalState: [
      "under_commitment",
      "under_process",
      "complete",
      "delivered_to_golden_group",
      "delivered_to_consultant",
      "delivered_to_client",
    ],
    
    appraisal: this.data.appraisal
  };

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  private _includes: string[] = ["user"];

  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    console.log(this.data.appraisal.id);
    
    this.appraisalsHistory = this.store$.pipe(
      select(AppraisalHistorySelectors.getAppraisalsHistory),
      takeUntil(this.unsubscribe$),
      map((dtos) =>
        dtos ? dtos.map((dto) => new AppraisalHistory(dto)) : null
      )
    );
    this.total = this.store$.pipe(
      select(AppraisalHistorySelectors.getTotalAppraisalsHistory),
      takeUntil(this.unsubscribe$)
    );
    this.filters = this.store$.pipe(
      select(AppraisalHistorySelectors.getFilters),
      takeUntil(this.unsubscribe$)
    );

    this.load();
  }

  load() {
    this.store$.dispatch(
      AppraisalHistoryActions.loadAppraisalsHistory({
        page: 1,
        perPage: this.pageSizeOptions[0],
        filters: this.defaultFilters,
        includes: this._includes,
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(
      AppraisalHistoryActions.changeSort({
        order: sort.active,
        direction: sort.direction,
      })
    );
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(
      AppraisalHistoryActions.changePage({
        page: pageEvent.pageIndex + 1,
        size: pageEvent.pageSize,
      })
    );
  }

  filtersChange(filters: AppraisalFilters) {
    this.store$.dispatch(AppraisalHistoryActions.changeFilters({ filters }));
  }

  get displayedColumns(): string[] {
    return ["initialSate", "finalState", "date", "user"];
  }

  close() {
    this.store$.dispatch(AppraisalActions.closeHistoryDialog());
  }
}
