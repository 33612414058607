import { Action, createReducer, on } from '@ngrx/store';
import { AppraisalDTO } from 'src/app/commons/models/appraisal.model';
import * as AppraisalActions from '../actions/appraisal.actions';
import { AppraisalFilters } from './../../commons/models/appraisal.model';

export interface AppraisalState {
  list: AppraisalDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: AppraisalFilters,
  dialogId: string,
  selectionDialogId: string,
  historyDialogId: string,
  filterDialogId: string,
  statusDialogId: string,
  barcodeDialogId: string,
  scanDialogId: string
};

const initialState: AppraisalState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  historyDialogId: null,
  filterDialogId: null,
  statusDialogId: null,
  barcodeDialogId: null,
  scanDialogId: null
};

const appraisalReducer = createReducer(
  initialState,
  on(AppraisalActions.loadAppraisalsCompleted, (state, { appraisals, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: appraisals, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(AppraisalActions.appraisalDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(AppraisalActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(AppraisalActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(AppraisalActions.historyDialogOpened, (state, { historyDialogId }) => {
    return { ...state, historyDialogId };
  }),
  on(AppraisalActions.appraisalFilterDialogOpened, (state, { filterDialogId }) => {
    return { ...state, filterDialogId };
  }),
  on(AppraisalActions.appraisalStatusDialogOpened, (state, { statusDialogId }) => {
    return { ...state, statusDialogId };
  }),
  on(AppraisalActions.barcodeDialogOpened, (state, { barcodeDialogId }) => {
    return { ...state, barcodeDialogId };
  }),
  on(AppraisalActions.scanDialogOpened, (state, { scanDialogId }) => {
    return { ...state, scanDialogId };
  })
);

export function reducer(state: AppraisalState | undefined, action: Action) {
  return appraisalReducer(state, action);
}

