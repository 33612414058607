import { AbstractControl } from '@angular/forms';
import { Contract, ContractDTO } from 'src/app/commons/models/contract.model';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { Base, BaseDTO } from './base.model';
import { Client, ClientDTO } from './client.model';
import { User, UserDTO } from './user.model';

export type AppraisalState = "under_commitment" | "under_process" | "complete" | "delivered_to_golden_group" | "delivered_to_consultant" | "delivered_to_client";

export interface AppraisalFilters {
  id?: string;
  state?: AppraisalState[];
  client?: Client;
  consultant?: User;
  supplier?: User;
  consultantInCharge?: User;
  province?: string;
  article?: string;
  creationStart?: Date;
  creationEnd?: Date;
  updateStart?: Date;
  updateEnd?: Date;
  externalId?: string;
  blocked?: boolean;
  cancelled?: boolean;
  includeArchived?: boolean;
}

export interface AppraisalDTO extends BaseDTO {
  state: AppraisalState;
  client_id: number;
  client: ClientDTO;
  contract_id: number;
  contract: ContractDTO;
  consultants: UserDTO[];
  supplier_id: number;
  supplier: UserDTO;
  consultant_in_charge_id: number;
  consultant_in_charge: UserDTO;
  confirmation: string;
  technician: string;
  amount: number;
  to_notify: string[];
  note: string;
  external_date: string;
  external_id: string;
  blocked: boolean;
  cancelled: boolean;
}

export class Appraisal extends Base {
  state: AppraisalState;
  client: Client;
  contract: Contract;
  consultants: User[];
  supplier: User
  consultantInCharge: User;
  confirmation: string;
  technician: string;
  amount: number;
  toNotify: string[];
  note: string;
  externalDate: Date;
  externalId: string;
  blocked: boolean;
  cancelled: boolean;

  constructor(source: AppraisalDTO) {
    super(source);
    if (source) {
      this.state = source.state;
      if (source.client) {
        this.client = new Client(source.client);
        this.addLoadedRelation('client');
      }
      if (source.contract) {
        this.contract = new Contract(source.contract);
        this.addLoadedRelation('cotract');
      }
      if (source.consultants) {
        this.consultants = source.consultants.map((dto) => new User(dto));
        this.addLoadedRelation('consultants');
      }
      if (source.supplier) {
        this.supplier = new User(source.supplier);
        this.addLoadedRelation('supplier');
      }
      if (source.consultant_in_charge) {
        this.consultantInCharge = new User(source.consultant_in_charge);
        this.addLoadedRelation('consultant_in_charge');
      }
      this.confirmation = source.confirmation;
      this.technician = source.technician;
      this.amount = source.amount;
      this.toNotify = source.to_notify;
      this.note = source.note;
      if (source.external_date) {
        this.externalDate = new Date(source.external_date);
      }
      this.externalId = source.external_id;
      this.blocked = source.blocked;
      this.cancelled = source.cancelled;
    }

  }
  get qrUrl(): string {
    return environment.baseUrl + `/storage/qr_codes/${this.id}.svg`;
  }

  toDTO(): AppraisalDTO {
    let result: AppraisalDTO = <AppraisalDTO>super.toDTO();
    result.state = this.state;
    result.client = this.client ? this.client.toDTO() : null;
    result.client_id = this.client ? this.client.id : null;
    result.contract = this.contract ? this.contract.toDTO() : null;
    result.contract_id = this.contract ? this.contract.id : null;
    result.consultants = this.consultants ? this.consultants.map((consultant) => consultant.toDTO()) : null;
    result.supplier = this.supplier ? this.supplier.toDTO() : null;
    result.supplier_id = this.supplier ? this.supplier.id : null;
    result.consultant_in_charge = this.consultantInCharge ? this.consultantInCharge.toDTO() : null;
    result.consultant_in_charge_id = this.consultantInCharge ? this.consultantInCharge.id : null;
    result.confirmation = this.confirmation;
    result.technician = this.technician;
    result.amount = this.amount;
    result.to_notify = this.toNotify;
    result.note = this.note;
    result.external_date = this.externalDate ? formatDateForBackend(this.externalDate) : null
    result.external_id = this.externalId;
    result.blocked = this.blocked;
    result.cancelled = this.cancelled;

    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Appraisal) {
    const formModel = formGroup.value;
    let appraisal: Appraisal = new Appraisal(null);
    appraisal.state = formModel.state;
    appraisal.client = formModel.client;
    appraisal.contract = formModel.contract;
    appraisal.consultants = formModel.consultants;
    appraisal.supplier = formModel.supplier;
    appraisal.consultantInCharge = formModel.consultantInCharge;
    appraisal.confirmation = formModel.confirmation;
    appraisal.technician = formModel.technician;
    appraisal.amount = formModel.amount;
    appraisal.toNotify = formModel.toNotify;
    appraisal.note = formModel.note;
    appraisal.externalDate = formModel.externalDate;
    appraisal.blocked = formModel.blocked;
    appraisal.cancelled = formModel.cancelled;

    if (original) {
      appraisal.id = original.id;
      appraisal.externalId = original.externalId;
    }
    return appraisal;
  }
}
