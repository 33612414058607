import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';

export type Role = "admin" | "consultant" | "supplier";

export interface UserFilters {
  search?: string;
  roles?: Role[];
  ids?: number[];
}

export interface UserDTO extends BaseDTO {
  name?: string;
  surname?: string;
  email?: string;
  username: string;
  password: string;
  user_roles: Role[];
}

export class User extends Base {
  email?: string;
  surname: string;
  username: string;
  name: string;
  password: string;
  role?: Role;

  constructor(source: UserDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.email = source.email;
      this.password = source.password;
      if (source.user_roles) {
        this.role = source.user_roles[0];
      }
    }
  }

  toDTO(): UserDTO {
    let result: UserDTO = <UserDTO>super.toDTO();
    result.name = this.name;
    result.email = this.email;
    result.password = this.password;
    result.user_roles = [];
    if (this.role) {
      result.user_roles.push(this.role);
    }
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: User) {
    const formModel = formGroup.value;
    let user: User = new User(null);
    user.name = formModel.name;
    user.email = formModel.email;
    user.password = formModel.password;
    user.role = formModel.role;
    if (original) {
      user.id = original.id;
    }
    return user;
  }


  get admin(): boolean {
    return this.hasRole("admin");
  }

  get consultant(): boolean {
    return this.hasRole("consultant");
  }

  get supplier(): boolean {
    return this.hasRole("supplier");
  }

  hasRole(role: Role): boolean {
    return this.role && this.role === role;
  }
}
