<perizie-appraisal-history-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</perizie-appraisal-history-filters>
<mat-card class="u-center-text" *ngIf="!(appraisalsHistory | async).length">
  <h4>Nessun storico trovato</h4>
</mat-card>
<div [hidden]="!(appraisalsHistory | async).length">
  <table mat-table matSort matSortDirection="asc" [dataSource]="appraisalsHistory">
    <ng-container matColumnDef="initialState">
      <th mat-header-cell *matHeaderCellDef> Stato iniziale </th>
      <td class="state-cell" mat-cell *matCellDef="let appraisalHistory">
        {{ appraisalHistory.initialState | appraisalStatus}}
      </td>
    </ng-container>
    <ng-container matColumnDef="finalState">
      <th mat-header-cell *matHeaderCellDef > Stato finale </th>
      <td class="state-cell" mat-cell *matCellDef="let appraisalHistory">
        {{ appraisalHistory.finalState | appraisalStatus}}
      </td>
    </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="created_at"> Data </th>
        <td mat-cell *matCellDef="let appraisalHistory">
          {{appraisalHistory.createdAt | date: 'dd-MM-yyyy' }}
        </td>
      </ng-container>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef> Utente</th>
      <td mat-cell *matCellDef="let appraisalHistory">
        {{appraisalHistory.user.name }}
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
