
import { ContractFieldComponent } from './contract-field/contract-field.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';

import { MaterialModule } from './../material/material.module';
import { AppraisalFiltersComponent } from './appraisal-filters/appraisal-filters.component';
import { AppraisalListComponent } from './appraisal-list/appraisal-list.component';
import { AppraisalStatusComponent } from './appraisal-status/appraisal-status.component';
import { ClientsFieldComponent } from './clients-field/clients-field.component';
import { ClientFieldComponent } from './client-field/client-field.component';
import { ClientFiltersComponent } from './client-filters/client-filters.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientSelectionComponent } from './client-selection/client-selection.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContractFiltersComponent } from './contract-filters/contract-filters.component';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractSelectionComponent } from './contract-selection/contract-selection.component';
import { ShowForDirective } from './directives/show-for.directive';
import { UserRolePipe } from './pipes/user-role.pipe';
import { UserFieldComponent } from './user-field/user-field.component';
import { UserFiltersComponent } from './user-filters/user-filters.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserSelectionComponent } from './user-selection/user-selection.component';
import { AppraisalStatusPipe } from './pipes/appraisal-status.pipe';
import { AppraisalHistoryFiltersComponent } from './appraisal-history-filters/appraisal-history-filters.component';
import { AppraisalHistoryListComponent } from './appraisal-history-list/appraisal-history-list.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { UsersFieldComponent } from './users-field /users-field.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ShowForDirective,
    UserRolePipe,
    UserFieldComponent,
    UserListComponent,
    UserFiltersComponent,
    UserSelectionComponent,
    ClientListComponent,
    ClientFiltersComponent,
    ClientSelectionComponent,
    ClientsFieldComponent,
    ClientFieldComponent,
    ContractListComponent,
    ContractFiltersComponent,
    ContractSelectionComponent,
    ContractFieldComponent,
    AppraisalListComponent,
    AppraisalFiltersComponent,
    AppraisalStatusComponent,
    AppraisalStatusPipe,
    AppraisalHistoryFiltersComponent,
    AppraisalHistoryListComponent,
    AttachmentComponent,
    UsersFieldComponent
   
  ],
  declarations: [
    ConfirmDialogComponent,
    ShowForDirective,
    UserRolePipe,
    UserFieldComponent,
    UserListComponent,
    UserFiltersComponent,
    UserSelectionComponent,
    ClientListComponent,
    ClientFiltersComponent,
    ClientSelectionComponent,
    ClientFieldComponent,
    ClientsFieldComponent,
    ContractListComponent,
    ContractFiltersComponent,
    ContractSelectionComponent,
    ContractFieldComponent,
    AppraisalListComponent,
    AppraisalFiltersComponent,
    AppraisalStatusComponent,
    AppraisalStatusPipe,
    AppraisalHistoryFiltersComponent,
    AppraisalHistoryListComponent,
    AttachmentComponent,
    UsersFieldComponent
  
  ],
  entryComponents: [ConfirmDialogComponent],
  providers: []
})
export class SharedModule { }
