<mat-expansion-panel
  style="margin-bottom: 16px"
  hideToggle
  [expanded]="currentUser?.role != 'admin'"
>
  <mat-expansion-panel-header> Filtra </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Stato</mat-label>
        <mat-select formControlName="state" multiple>
          <mat-select-trigger>
            <span *ngIf="filtersForm.value.state?.length == statuses.length"
              >Tutti</span
            >
            <span *ngIf="filtersForm.value.state?.length < statuses.length">
              {{
                (filtersForm.value.state ? filtersForm.value.state[0] : "")
                  | appraisalStatus
              }}
              <span
                *ngIf="filtersForm.value.state?.length > 1"
                class="additional-selection"
              >
                (+{{ filtersForm.value.state.length - 1 }}
                {{ filtersForm.value.state?.length === 2 ? "altro" : "altri" }})
              </span>
            </span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let state of statuses"
            [value]="state"
            [ngClass]="state"
          >
            {{ state | appraisalStatus }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Cliente</mat-label>
        <input type="text" matInput formControlName="client" />
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Provincia</mat-label>
        <input type="text" matInput formControlName="province" />
      </mat-form-field>
      <mat-form-field
        *ngIf="currentUser?.role == 'admin'"
        floatLabel="always"
        class="u-full-width"
      >
        <mat-label>Fornitore</mat-label>
        <perizie-user-field
          [userFilters]="supplierFilter"
          formControlName="supplier"
        >
        </perizie-user-field>
      </mat-form-field>
      <mat-form-field
        *ngIf="currentUser?.role == 'admin'"
        floatLabel="always"
        class="u-full-width"
      >
        <mat-label>Consulente</mat-label>
        <perizie-user-field
          [userFilters]="consultantFilter"
          formControlName="consultant"
        >
        </perizie-user-field>
      </mat-form-field>
      <mat-form-field
        *ngIf="currentUser?.role == 'admin'"
        floatLabel="always"
        class="u-full-width"
      >
        <mat-label>In carico</mat-label>
        <perizie-user-field
          [userFilters]="consultantFilter"
          formControlName="consultantInCharge"
        >
        </perizie-user-field>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Creazione - da </mat-label>
        <input
          matInput
          [matDatepicker]="fromDatePicker1"
          formControlName="creationStart"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="fromDatePicker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label> Creazione - a </mat-label>
        <input
          matInput
          [matDatepicker]="toDatePicker1"
          formControlName="creationEnd"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="toDatePicker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="currentUser?.role == 'admin'" class="u-full-width">
        <mat-label>Ultima modifica - da </mat-label>
        <input
          matInput
          [matDatepicker]="fromDatePicker2"
          formControlName="updateStart"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="fromDatePicker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker2></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="currentUser?.role == 'admin'" class="u-full-width">
        <mat-label> Ultima modifica - a </mat-label>
        <input
          matInput
          [matDatepicker]="toDatePicker2"
          formControlName="updateEnd"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="toDatePicker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width" style="flex: 2">
        <mat-label>ID CRM</mat-label>
        <input type="text" matInput formControlName="externalId" />
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Articolo</mat-label>
        <input
          type="text"
          placeholder="Schegli articolo"
          aria-label="Number"
          matInput
          formControlName="article"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let article of filteredArticles | async"
            [value]="article"
          >
            {{ article }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Bloccata</mat-label>
        <mat-select formControlName="blocked">
          <mat-option [value]="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Annullata</mat-label>
        <mat-select formControlName="cancelled">
          <mat-option [value]="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox
        formControlName="includeArchived"
        matTooltip="Mostra anche perizie archiviate"
        >Archiviate</mat-checkbox
      >
    </div>
    <span class="u-flex-full">
      <span>
        <button
          mat-raised-button
          [disabled]="filtersForm.pristine"
          color="primary"
          (click)="applyFilters()"
        >
          Filtra
        </button>
        <button
          mat-icon-button
          *ngIf="!filtersForm.pristine"
          (click)="resetFilters()"
          matTooltip="Reset"
        >
          <mat-icon>replay</mat-icon>
        </button>
      </span>
      <button
        *ngIf="currentUser?.role != 'admin'"
        mat-raised-button
        (click)="close()"
      >
        Annulla
      </button>
    </span>
  </form>
</mat-expansion-panel>
