import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';
import { ClientDTO, ClientFilters } from '../../models/client.model';


@Injectable({
  providedIn: "root"
})
export class LaravelClientService {


  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/clients",
      show: environment.baseUrl + "/api/client",
      store: environment.baseUrl + "/api/client",
      destroy: environment.baseUrl + "/api/client",
      
    };
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters: ClientFilters
  ): Observable<ListResultDTO<ClientDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (filters) {
      if (filters.search) params["search"] = "" + filters.search;
      if (filters.ids) params["ids[]"] = filters.ids;
      if (filters.contractIds) params["contract_ids[]"] = filters.contractIds;
  }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<ClientDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<ClientDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  public getClientById(id: number): Observable<ClientDTO> {
    let params = { id: "" + id };
    // if (include) params["include[]"] = include;
    return this.httpClient.get<ClientDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });

  }

  public upsert(client: ClientDTO): Observable<ClientDTO> {
    if (client.id) {
      return this.httpClient.put<ClientDTO>(`${this.ROUTES.store}`, { client });
    } else {
      return this.httpClient.post<ClientDTO>(`${this.ROUTES.store}`, { client });
    }
  }

  public delete(id: number): Observable<any> {
    let params = { id: "" + id };
    return this.httpClient.delete(this.ROUTES.destroy, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }
}








