<header mat-dialog-title class="u-flex-full">
  <span>
    Seleziona contratto
  </span>
</header>
<mat-dialog-content>
  <perizie-contract-list [contracts]="contracts" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns" [total]="total"
    [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectContract)="selectContract($event)"></perizie-contract-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
