import { createAction, props } from '@ngrx/store';

import { Appraisal, AppraisalDTO, AppraisalFilters } from '../../commons/models/appraisal.model';

export const loadAppraisals = createAction('[Appraisals] Load appraisals', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: AppraisalFilters, includes?: string[] }>());
export const loadAppraisalsCompleted = createAction('[Appraisals] Load appraisals Completed', props<{ appraisals: AppraisalDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: AppraisalFilters, includes?: string[] }>());
export const loadAppraisalsFailed = createAction('[Appraisals] Load appraisals Failed', props<{ error: any }>());

export const loadAppraisalById= createAction('[Appraisal] Load appraisal by id', props<{ id: string}>());
export const loadAppraisalByIdCompleted = createAction('[Appraisals] Load appraisal by id Completed', props<{ appraisal: AppraisalDTO}>());
export const loadAppraisalByIdFailed = createAction('[Appraisals] Load appraisal by id Failed', props<{ error: any }>());

export const changePage = createAction('[Appraisals] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Appraisals] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Appraisals] Change filters', props<{ filters: AppraisalFilters }>());

export const editAppraisal = createAction('[Appraisals] Edit appraisal', props<{ appraisal: Appraisal }>());
export const appraisalDialogOpened = createAction('[Appraisals] Detail dialog opened', props<{ dialogId: string }>());
export const closeAppraisalDialog = createAction('[Appraisals] Close detail dialog');

export const saveAppraisal = createAction('[Appraisals] Save appraisal', props<{ appraisal: Appraisal, attachment: any}>());
export const saveAppraisalCompleted = createAction('[Appraisals] Save appraisal completed', props<{ appraisal: AppraisalDTO }>());
export const saveAppraisalFailed = createAction('[Appraisals] Save appraisal failed', props<{ error: any }>());

export const deleteAppraisal = createAction('[Appraisals] Delete appraisal', props<{ appraisal: AppraisalDTO }>());
export const deleteAppraisalCompleted = createAction('[Appraisals] Delete appraisal completed', props<{ appraisal: AppraisalDTO }>());
export const deleteAppraisalCancelled = createAction('[Appraisals] Delete appraisal cancelled');
export const deleteAppraisalFailed = createAction('[Appraisals] Delete appraisal failed', props<{ error: any }>());

export const restoreAppraisal = createAction('[Appraisals] Restore appraisal', props<{ appraisal: AppraisalDTO }>());
export const restoreAppraisalCompleted = createAction('[Appraisals] Restore appraisal completed', props<{ appraisal: AppraisalDTO }>());
export const restoreAppraisalFailed = createAction('[Appraisals] Restore appraisal failed', props<{ error: any }>());

export const selectAppraisal = createAction('[Appraisals] Select appraisal', props<{ filters?: AppraisalFilters }>());
export const selectionDialogOpened = createAction('[Appraisals] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Appraisals] Close selection dialog');
export const appraisalSelected = createAction('[Appraisals] Appraisal selected', props<{ appraisal: AppraisalDTO }>());

export const openHistory = createAction('[Appraisals] Open history', props<{ appraisal: Appraisal }>());
export const historyDialogOpened = createAction('[Appraisals] History dialog opened', props<{ historyDialogId: string }>());
export const closeHistoryDialog = createAction('[Appraisals] Close history dialog');

export const openBarcode = createAction('[Appraisals] Open barcode', props<{ appraisal: Appraisal }>());
export const barcodeDialogOpened = createAction('[Appraisals] Barcode dialog opened', props<{ barcodeDialogId: string }>());
export const closeBarcodeDialog = createAction('[Appraisals] Close history barcode');

export const downloadAttachment = createAction('[Appraisal] download attachment', props<{ fileName: string}>());
export const filterAppraisal = createAction('[Appraisals] filter appraisal', props<{ filters: AppraisalFilters }>());
export const appraisalFilterDialogOpened = createAction('[Appraisals] Filter dialog opened', props<{ filterDialogId: string }>());
export const closeAppraisalFilterDialog = createAction('[Appraisals] Close filter dialog');

export const changeStatus = createAction('[Appraisals] change appraisal status', props<{ appraisal: Appraisal }>());
export const appraisalStatusDialogOpened = createAction('[Appraisals] Status dialog opened', props<{ statusDialogId: string }>());
export const closeAppraisalStatusDialog = createAction('[Appraisals] Close status dialog');

export const exportRecords = createAction("[Appraisals] Export records", props<{ filters?: AppraisalFilters }>());
export const exportRecordsCompleted = createAction("[Appraisals] Export records completed",props<{ blob: Blob }>());
export const exportRecordsFailed = createAction("[Appraisals] Export records failed",props<{ error: any }>());

export const downloadPDF = createAction('[Appraisal] download pdf', props<{ appraisal: Appraisal}>());
export const downloadPdfCompleted = createAction("[Appraisals] Download pdf completed",props<{ blob: Blob }>());
export const downloadPdfFailed = createAction("[Appraisals] Download pdf failed",props<{ error: any }>());

export const scanAppraisal = createAction('[Appraisal] Scan Appraisal');
export const scanDialogOpened = createAction('[Appraisals] Scan dialog opened', props<{ scanDialogId: string }>());
export const closeScanDialog = createAction('[Appraisals] Close scan dialog');
export const scanAppraisalSuccess = createAction('[Appraisal] Scan Appraisal Success', props<{ code: string }>());
export const scanAppraisalError = createAction('[Appraisal] Scan Appraisal Error');



