import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppraisalHistoryFilters } from 'src/app/commons/models/appraisal-history.model';
import { AppraisalState } from 'src/app/commons/models/appraisal.model';
import { Role, UserFilters } from 'src/app/commons/models/user.model';


@Component({
  selector: 'perizie-appraisal-history-filters',
  templateUrl: './appraisal-history-filters.component.html',
  styleUrls: ['./appraisal-history-filters.component.scss']
})
export class AppraisalHistoryFiltersComponent {
  statuses: AppraisalState[] = ["under_commitment", "under_process", "complete", "delivered_to_golden_group" ,"delivered_to_consultant", "delivered_to_client"];

    
  @Output()
  onFilter: EventEmitter<AppraisalHistoryFilters> = new EventEmitter();

  private _defaultFilters: AppraisalHistoryFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      initialState: [["Impegno di lavorazione", "In lavorazione", "Completata", "Consegnata a Golden Group" ,"Consegnata al consulente", "Consegnata al cliente"]],
      finalState: [["Impegno di lavorazione", "In lavorazione", "Completata", "Consegnata a Golden Group" ,"Consegnata al consulente", "Consegnata al cliente"]],
      user: [""],
      creationStart: [""],
      creationEnd: [""],
      appraisal: [""]
  
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getAppraisalHistoryFilters());
  }

  private _getAppraisalHistoryFilters(): AppraisalHistoryFilters {
    let filters: AppraisalHistoryFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.initialState = values.initialState;
      filters.finalState = values.finalState;
      filters.user = values.user;
      filters.creationStart = values.creationStart;
      filters.creationEnd = values.creationEnd;
      filters.appraisal = values.appraisal;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      initialState: [""],
      finalState: [""],
      user: [""],
      creationStart: [""],
      creationEnd: [""],
      appraisal:[""]

    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): AppraisalHistoryFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: AppraisalHistoryFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
         initialState: this.defaultFilters.initialState,
         finalState: this.defaultFilters.finalState,
         user: this.defaultFilters.user,
         creationStart: this.defaultFilters.creationStart,
         creationEnd: this.defaultFilters.creationEnd,
         appraisal:this.defaultFilters.appraisal
    
        })
      }
    }
  }
}
