import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { Appraisal, AppraisalFilters } from 'src/app/commons/models/appraisal.model';

import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';


export type AppraisalsColumn = "state" | "article" | "client" | "province" | "contract" | "supplier" | "consultant" | "consultantInCharge" | "creation" | "lastUpdate" | "amount" | "note" | "externalId" | "actions";

@Component({
  selector: 'perizie-appraisal-list',
  templateUrl: './appraisal-list.component.html',
  styleUrls: ['./appraisal-list.component.scss']
})
export class AppraisalListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  appraisals: Observable<Appraisal[]>;

  @Input()
  defaultFilters: AppraisalFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: AppraisalsColumn[] = ["state", "externalId", "article", "client", "province", "contract", "supplier", "consultant", "consultantInCharge", "creation", "lastUpdate", "amount", "note", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<AppraisalFilters> = new EventEmitter<AppraisalFilters>();

  @Output()
  onSelectAppraisal: EventEmitter<Appraisal> = new EventEmitter<Appraisal>();
  @Output()
  onAddAppraisal: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditAppraisal: EventEmitter<Appraisal> = new EventEmitter<Appraisal>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addAppraisal() {
    this.onAddAppraisal.emit();
  }

  editAppraisal(appraisal: Appraisal) {
    this.onEditAppraisal.emit(appraisal);
  }

  selectAppraisal(appraisal: Appraisal) {
    this.onSelectAppraisal.emit(appraisal);
  }

  onFilter(filters: AppraisalFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}
