import { Action, createReducer, on } from '@ngrx/store';
import { ContractDTO } from 'src/app/commons/models/contract.model';
import * as ContractActions from '../actions/contract.actions';
import { ContractFilters } from './../../commons/models/contract.model';

export interface ContractState {
  list: ContractDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ContractFilters,
  dialogId: string,
  selectionDialogId: string
};

const initialState: ContractState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null
};

const contractReducer = createReducer(
  initialState,
  on(ContractActions.loadContractsCompleted, (state, { contracts, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: contracts, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(ContractActions.contractDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ContractActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ContractActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: ContractState | undefined, action: Action) {
  return contractReducer(state, action);
}

